import Cookies from 'universal-cookie';
import { DEEGRAM_IMAGE_API } from '../constants';

/**
 * 
 * @param {Blob} files 
 */
export const uploadImage = (files) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();

    const uuidv4 = require('uuid/v4');

    for (let file of files) {
      var newUuid = uuidv4();    
      formData.append('File', file, newUuid);
    }
    
    const cookies = new Cookies();
    const authToken = cookies.get('token');

    fetch(DEEGRAM_IMAGE_API, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': authToken
        },
        body: formData
    })
    .then(res => res.json())
    .then(result => {
      if (result.id) {
        resolve(result.id);
      }
      else {
        reject('No image id returned from server: ' + result);
      }
    })
    .catch(err => {
      //console.log(err);
      reject('Error connecting to server: ' + err);
    })
  })
} 