import React from 'react';
import {Button} from 'react-bootstrap';
import './profileCSS.css';
import { GetFeedContent } from '../../blogFeed/getFeedContent';
import {followAccount, unfollowAccount} from '../../blockchainApi/followAccount';
import {ClipLoader, BeatLoader} from 'react-spinners';
import { getHiveAvatarImg, URL_EDIT_PROFILE, getUrlWallet, URL_NEW_POST } from '../../constants';
//egen klasse for visning av profilinformasjon --> profile.js

export default class ProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      feedMode: this.props.feedMode ? this.props.feedMode : '',
      userName: this.props.userInfo.userName,
      isFollowing: this.props.isFollowing, //initial value needs to be null based on checks later on
      followers: this.props.userInfo.fllowerCount,
      followLoading: false,
      showSharedPosts: true,
    }
  }

  handleFollowButton = () => {
    if (this.props.userInfo.userName === localStorage.getItem('userName')) {
      alert('You cannot follow yourself!');
      return;
    }
    this.setState({followLoading: true}, () => {
      if (this.props.isFollowing) {
        unfollowAccount(this.props.userInfo.userName)
        .then(res => {
          this.setState({isFollowing: false, followers: this.state.followers -1});
        })
        .catch(err => {

        })
        .finally(() => {
          this.setState({followLoading: false})
        })
      }
      else {
        followAccount(this.props.userInfo.userName)
        .then(res => {
          this.setState({isFollowing: true, followers: this.state.followers +1})
        })
        .catch(err => {
        
        })
        .finally(() => {
          this.setState({followLoading: false})
        })
      }
    });
  }

  handleNoContent = (callback) => {
    var message = 'It appears this user hasn\'t posted yet';
    return callback(message);
  }

  toggleSharedPosts = (e) => {
    this.setState({showSharedPosts: e.target.checked})
  }

  render() {    
    if (!this.props.userInfo.userName) {
      return (<div></div>)
    }
    if (this.props.userInfo.jsonMetadata) {
      //console.log(this.props.userInfo.jsonMetadata);
      var parsedJson = JSON.parse(this.props.userInfo.jsonMetadata);
      if (parsedJson && parsedJson.profile) {
        if (parsedJson.profile.about) {
          var about = (
            <div>
              {parsedJson.profile.about}
            </div>
          )
        }
        if (parsedJson.profile.profile_image) {
          var profileImage = `https://images.hive.blog/1024x1024/${parsedJson.profile.profile_image}`;
        }
        if (parsedJson.profile.cover_image) {
          var coverImage = `https://images.hive.blog/2048x512/${parsedJson.profile.cover_image}`;
        }
      }
    }
    var coverImageBox = (
      <img className ='profile-banner-image' src={coverImage} />
    )

    var placeholderCoverImageBox = (
      <div className='profile-banner-image' style={{height: '150px'}}></div>
    )

    var followButtonText = 'Follow';
    if (this.state.isFollowing === null || this.state.isFollowing === undefined) {
      followButtonText = this.props.isFollowing ? 'Unfollow' : 'Follow';
    } else {
      followButtonText = this.state.isFollowing ? 'Unfollow': 'Follow';
    }
    if (!this.props.userInfo.ownProfile && localStorage.getItem('loggedIn')) {
      var followButton = (
        <Button onClick={this.handleFollowButton} className='profile-other-info-box profile-follow-button'>
          {followButtonText}
        </Button>
      )
    }

    var walletButton = (
      <Button href={getUrlWallet(this.props.userInfo.userName)}>
        Wallet
      </Button>
    )
    if (this.props.userInfo.ownProfile === true) {
      var editButton = (
        <Button href={URL_EDIT_PROFILE} className='profile-edit-button'>
          Edit profile
        </Button>
      )
      var newPostButton = (
        <Button href={URL_NEW_POST} className='profile-new-post-button'>
          New post
        </Button>
      )
    }

      var spinner = (   
        <div className='profile-other-info-box'>
          <BeatLoader
          sizeUnit={"px"}
          color={'#000'}
          loading={true}/>
      </div>
      )

    return (
        <div className='profile-columns'>
          <div className='profile-fixed-container'>
            <div className='profile-info-container'>
            <div className='profile-info' > 
              <div className='profile-picture-container'>
                <img className='profile-picture' 
                src={profileImage ? profileImage : getHiveAvatarImg(this.props.userInfo.userName)} 
                alt='profile avatar' />
              </div>
              <p className='profile-title'>{this.props.userInfo.userName}</p>
              <div className='profile-about'>         
                {about}
                <br></br>
                <p>Member since {this.props.userInfo.accountCreated}</p>
                {walletButton}
                {editButton}
                {newPostButton}
              </div>
            <div className='profile-other-info'>
              <div className='profile-other-info-box'>Followers: {this.props.userInfo.followerCount}</div>
              <div className='profile-other-info-box'>Following: {this.props.userInfo.followingCount}</div>
              <div className='profile-other-info-box'>Posts: {this.props.userInfo.postCount}</div>
              {this.state.followLoading ? spinner: followButton}
              <div>
                <input id='feed-shared-posts-checkbox' type='checkbox' checked={this.state.showSharedPosts} onChange={this.toggleSharedPosts}/>
                <label htmlFor='feed-shared-posts-checkbox'>Shared posts</label>
              </div>
            </div>   
            </div> 
            </div> 
          </div>

          {coverImage ? coverImageBox : ''}
          <div className='profile-feed'>       
            <GetFeedContent match={this.props.match} tag={this.props.userInfo.userName} feedType='blog' hideSharedPosts={!this.state.showSharedPosts}
            feedMode={this.props.feedMode} feedOptions={this.props.feedOptions} callback={this.props.callback} onNoContent={this.handleNoContent}/>
          </div>
        </div>)
    }
};  

ProfileView.defaultProps = {
  userInfo: {}
}
