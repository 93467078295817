import { getAccountHistory } from './getAccountHistory';
import { URL_POST } from '../constants';


export const getNotifications = (from = -1, limit = 1000) => {
  return new Promise((resolve, reject) => {
    var name = localStorage.getItem('userName');
    getAccountHistory(name, from, limit)
    .then((res) => {
      try {
        var notifications = []
        var lastNotification = res[0][0];
        for (var result of res) {
          var number = result[0];
          var type = result[1].op[0];
          var action = result[1].op[1];
          var timestamp = result[1].timestamp;
          
          var rootUrl = '';
          var notification = '';
          var user = '';
          var message = '';
          var link = '';
          var other = {};
          switch(type) {
            case 'comment':
              if (action.author === name) {
                continue;
              }
              if (action.permlink.startsWith('re-')) {
                message = 'replied to your comment';
              }
              else {
                message = 'commented on your post';
              }
              rootUrl = `${action.parent_author}/${action.parent_permlink}#${action.author}/${action.permlink}`;
              link = `${URL_POST}/${rootUrl}`;
              user = action.author;
              break;
              
            case 'create_claimed_account':
              //console.log(action);
              continue;
    
            case 'custom_json':
              continue;

            case 'delegate_vesting_shares':
              continue;
              /*
              if (action.from === name) {
                skip = true;
                break;
              }
              user = action.delegator;
              message = `delegated ${action.vesting_shares} to your account`;
              break;
              */
    
            case 'transfer':
              continue;
              /*
              if (action.from === name) {
                skip = true;
                break;
              }
              user = action.from;
              message = `sent you ${action.amount}`
              other = {
                amount: action.amount,
                message: action.memo
              }
              
              //console.log(`${action.from} sent you ${action.amount} : ${action.memo}`)
              //notifications.push(`${action.from} sent you ${action.amount} : ${action.memo}`)
              break;
              */

            case 'vote':
              if (action.voter === name) {
                continue;
              }
              if (action.permlink.startsWith('re-')) {
                message = 'voted on your comment';
              }
              else {
                message = 'voted on your post';
              }
              rootUrl = `${action.author}/${action.permlink}`
              link = `${URL_POST}/${rootUrl}`
              user = action.voter;
              break;
    
            default:
              continue;
          }        
          notification = {
            href: link,
            user: user,
            message: message,
            timestamp: timestamp,
            number: number,
            type: type,
            other: other,
          }          
          notifications.push(notification)
        }
      //console.log(notifications)
      //array received from api is in reverse chronologial order - TODO sort based on time?
      notifications = notifications.reverse();
      /*
      //alternatively, sort based on their history index in the api
      notifications.sort((a, b) => {
        return b.number - a.number
      })
      */
      return resolve({notifications: notifications, lastNotification: lastNotification});
    }
    catch (ex) {
      return reject(ex);
    }
    })
    .catch(err => reject(err))
  })
}