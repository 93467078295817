import React from 'react';
import { Button } from 'react-bootstrap';

export class ErrorMessageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        error: this.props.error
    };
  };

  render() {
    const lastPage = () => {
      window.history.back()
    };
    return(
        <div className='result-error-message'>
            <h3>Error loading content</h3>
            <h4>Please try again later</h4>
            <p>Reason: {this.state.error}</p>
            <Button onClick={lastPage}>Go back</Button>
            <br />
            <Button href='/'>Go home</Button>
        </div>
    );
  }
}
