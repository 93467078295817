import React from 'react';
import {Link} from 'react-router-dom';
import {Panel} from 'react-bootstrap';
import {CommentSectionView} from './commentSectionView';
import { convertBody } from '../../helpers/convertBody';
import { convertTime } from '../../helpers/convertTime';
import {getContentReplies} from '../../blockchainApi/getContentReplies';
import {postBlogForm} from '../../blockchainApi/postBlogForm';
import {PostCommentView} from './postCommentView';
import {hasVoted} from '../../helpers/hasVoted';
import {voteWeight, getUrlProfile, getHiveAvatarImg} from '../../constants';
import {VoteButton} from '../../items/voteButton';
import { getActiveVotes } from '../../blockchainApi/getActiveVotes';
import { VotesOnPostView } from '../../views/votesOnPostView';

//viser en kommentar til et innlegg. oppretter et kommentarfelt for
// å vise svar til kommentaren. Denne klassen er skrevet en del om
//og bærer preg av det, litt rot
export class CommentBox extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      contentReplies: [],
      loading: false,
      hasVoted: false,
      netVotes: 0,
      weight: voteWeight,
      voteError: '',
      commentError: '',
      viewVotes: false,
      indentation: this.props.indentation ? this.props.indentation : 0,
    }
  }

  getReplies = () => {
    getContentReplies(
      this.props.reply.author,
      this.props.reply.permlink
    )
    .then(res => {
        this.setState({contentReplies: res});
      }
    )
    .catch(err => {
      console.err(err);
    })
  }

  handleSubmitComment = (commentBody, parentAuthor, parentPermlink) => {
    return new Promise((resolve, reject) => {
      postBlogForm(
        parentAuthor, 
        parentPermlink,
        this.props.userName,
        '', //permlink
        '', //title
        commentBody,
        {}, //jsonMetadata,
      )
      .then(res => {
        this.getReplies();
        resolve();
      })
      .catch(err => {
        this.setState({commentError: err.error_description})
        reject();
      })
    })
  }

  componentDidMount(){
    //gets top level replies to the post / comment
    this.getReplies();
    getActiveVotes(this.props.reply.author, this.props.reply.permlink)
    .then(res => {
      this.setState({netVotes: res.length, hasVoted: hasVoted(res), activeVotes: res});
    })
  }
  
  handleUpVotePost = (votedData) => {
    this.setState({hasVoted: votedData.hasVoted, activeVotes: votedData.activeVotes})
  }

  viewVotes = () => {
    this.setState({viewVotes: !this.state.viewVotes});
  }

  render() {
    if (JSON.parse(localStorage.getItem('loggedIn'))) {//parse converts string 'true' or 'false' to a boolean
      var replyButton = (
        <PostCommentView
          onSubmit={this.handleSubmitComment}
          userName={this.props.userName}
          parentAuthor={this.props.reply.author}
          parentPermlink={this.props.reply.permlink}
          commentError={this.state.commentError}
        />)
    }
    var author = <Link to={getUrlProfile(this.props.reply.author)}>{this.props.reply.author}</Link>
    var parentAuthor = <Link to={getUrlProfile(this.props.reply.parent_author)}>{this.props.reply.parent_author}</Link>
    //some posts crash when html is converted in comments - edit: now set to display unformatted body
    //i.e. @jaynie/jaynie-1562752836251-to-the-mofo-who-robbed-my-moms-steem-wallet-screw-you
    var body = convertBody(this.props.reply.body, true);
    

    var timeAgo = convertTime(this.props.reply.created);
    var timePosted = (<p className='comment-time-posted'>{timeAgo}</p>)

    var voteData = {      
      weight: this.state.weight,
      voter: this.props.userName,
      author: this.props.reply.author,
      permlink: this.props.reply.permlink,
      activeVotes: this.state.activeVotes,
      hasVoted: this.state.hasVoted
    }

    var voteButton = (      
      <VoteButton className='preview-vote-item' 
      voteData={voteData}
      loading={this.state.loading} 
      handleUpVotePost={this.handleUpVotePost}
      voteError={this.state.voteError}/>
    )

    if (this.state.viewVotes) {
      var viewVotesWindow = (
        <VotesOnPostView 
          author={this.props.reply.author}
          permlink={this.props.reply.permlink}
          show={this.state.viewVotes}
          votes={this.state.activeVotes}
          onHide={this.viewVotes}
          isComment={true} />
      )
    }
    
    var isMobile = window.matchMedia('only screen and (max-width: 767px').matches;
    var indentationThreshold = isMobile ? 2 : 5;

    var commentId = `${this.props.reply.author}/${this.props.reply.permlink}`;
    var isHighlighted = commentId === this.props.highlighted;
    return(
      <div id={commentId}
      className={isHighlighted ? 'comment-highlighted' : ''}>
        <Panel className='readpost-comment-container'>
          <Panel.Heading className='comment-header'>
            <Panel.Title className='comment-title'>
              <img src={getHiveAvatarImg(this.props.reply.author)} className='comment-author-avatar'/>
              {author} @ {parentAuthor} {timePosted}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body className='readpost-comment-body'>
            {body}
          </Panel.Body>
          <Panel.Heading className='readpost-comment-bottom-bar'>
            <Panel.Title className='readpost-comment-votes'> 
            {voteButton} 
            </Panel.Title>
            <div>$ {this.props.reply.pending_payout_value.slice(0, -5)}</div>
          </Panel.Heading>
          <Panel.Heading>{replyButton}</Panel.Heading>
        </Panel>
        <div style={this.state.indentation > indentationThreshold ? {} : {marginLeft: '50px'}}>
          <CommentSectionView
            parentAuthor={this.props.reply.author}
            parentPermlink={this.props.reply.permlink}
            userName={this.props.userName}
            contentReplies={this.state.contentReplies}
            indentation={this.state.indentation + 1}/>
        </div>
        {viewVotesWindow}
      </div>
    )
  }
}
