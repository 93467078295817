export const minVoteWeight = 0;
export const maxVoteWeight = 100; //is 10000 (100%), gets multiplied by 100 in vote function
var voteWeightString = localStorage.getItem('voteWeight');
var parsedVoteWeight = parseInt(voteWeightString);
export var voteWeight = parsedVoteWeight ? parsedVoteWeight : maxVoteWeight;
export const getVoteWeight = () => {
  return parsedVoteWeight ? parsedVoteWeight : maxVoteWeight;
}
export const setVoteWeight = (weight) => {
    var parsedWeight = parseInt(weight);
    voteWeight = parsedWeight ? parsedWeight: maxVoteWeight;
}
export const voteSliderStep = 1;

export var feedMode = 'image';

export const KEYWORD_FOR_DELETION = 'DELETED';

export const HIVE_AVATAR_URL = 'https://images.hive.blog/u/';

export const getHiveAvatarImg = (account) => {
    return `${HIVE_AVATAR_URL}${account}/avatar`
}

export const URL_APP_LINKS = '/apps';

export const URL_DISCOVER  = '/discover';

export const URL_EDIT_PROFILE = '/editprofile';

export const URL_NEW_POST = '/createpost';

export const URL_NOTIFICATIONS = '/notifications';

export const URL_POST = '/post';
export const getUrlPost = (author, permlink) => {
    return `${URL_POST}/${author}/${permlink}`
}

export const URL_PROFILE = '/profile';
export const getUrlProfile = (account) => {
    return `${URL_PROFILE}/${account}/`
}

export const URL_SEARCH = '/search';
export const getUrlSearch = (query = '') => {
    return `${URL_SEARCH}/${query}`
}

export const URL_SETTINGS = '/settings';

export const URL_TAG = '/tag';
export const getUrlTag = (tag, type = '') => {
  if (!tag) {
    return `/${type}`
  }
  if (tag === '___') {
    return `/all-tags/${type}`
  }
  return `${URL_TAG}/${tag}/${type}`
}

export const URL_WALLET = '/wallet';
export const getUrlWallet = (account) => {
    return `${URL_WALLET}/${account}`
}

export const URL_FEEDBACK_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSedrpKsJItSvRgQhR0DfKQ5xK-MwEEtRtsWgjYn8Kfqkypb6Q/viewform';

export const URL_REGISTER_LINK = 'https://register.deegram.com';

//export const urlAboutDeegram = 'https://deegram.com/the-concept';

export const URL_LICENSES = '/webclient-licenses';


/* PLACEHOLDER VALUES */
export const PLACEHOLDER_HIVE_RATE = '0.23';
export const PLACEHOLDER_HBD_RATE = '0.98';


/* APIS */
export const steemConnectEditProfile = 'https://app.steemconnect.com/sign/profile-update';

export const DEEGRAM_FEED_API = 'https://deegram.azurewebsites.net/api/feed/';
export const DEEGRAM_TEST_FEED_API = 'https://deegram-test.azurewebsites.net/api/feed';

export const DEEGRAM_IMAGE_API = 'https://deegram.azurewebsites.net/api/images';
export const DEEGRAM_TEST_IMAGE_API = 'https://deegram-test.azurewebsites.net/api/images';

export const getDeegramImage = (imageId) => {
  return `${DEEGRAM_IMAGE_API}/${imageId}`
}

export const HIVE_RATE_API = 'https://api.cryptonator.com/api/ticker/HIVE-usd';
export const HBD_RATE_API = 'https://api.cryptonator.com/api/ticker/hbd-usd';