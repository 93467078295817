import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { getAccounts } from '../../blockchainApi/getAccounts';
import { getHiveAvatarImg, HIVE_RATE_API, HBD_RATE_API, PLACEHOLDER_HIVE_RATE, PLACEHOLDER_HBD_RATE } from '../../constants';
import {convertToFloat} from '../../helpers/convertToFloat';
import { getDynamicGlobalProperties } from '../../blockchainApi/getDynamicGlobalProperties';
import './walletPage.css';

export class WalletPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            statsArray: [],
            votingPower: null,
            showDonatePopup: false,
            profile_image: getHiveAvatarImg(this.props.account)
        }
    }

    //TODO: convert to seperate call
    handleResponse = async (result) => {
        const hiveRate = await fetch(HIVE_RATE_API, {
            method: 'GET'
        })
        .then(res => res.json())
        .then(res => convertToFloat(res.ticker.price))
        .catch(err => console.log(err));

        const hbdRate = await fetch(HBD_RATE_API, {
            method: 'GET'
        }).then(res => res.json())
        .then(res => convertToFloat(res.ticker.price))
        .catch(err => console.log(err));

        let r = result[0];
        let convertedJson = JSON.parse(r.json_metadata);
        if (convertedJson && convertedJson.profile && convertedJson.profile.profile_image) {
          this.setState({profile_image: convertedJson.profile.profile_image})
        }
        this.setState({
            stats: r,
            name: r.name,
            balance: r.balance,
            hbd_balance: r.hbd_balance
        }, () => {
            getDynamicGlobalProperties()
            .then(res => {
              let hiveVestsString = res.total_vesting_shares;
              let hivePowerString = res.total_vesting_fund_hive;
              let vests = convertToFloat(hiveVestsString);
              let power = convertToFloat(hivePowerString);

              let powerRatio = vests / power;
              let votingPower = convertToFloat(this.state.stats.vesting_shares);
              votingPower = votingPower / powerRatio;
              votingPower = Math.round((votingPower + 0.000001) * 1000) / 1000;
              this.setState({votingPower: votingPower});

              let hiveBalance = convertToFloat(this.state.balance);
              let hbdBalance = convertToFloat(this.state.hbd_balance);
              let hiveValue = hiveRate ? (hiveBalance * hiveRate) : (hiveBalance * PLACEHOLDER_HIVE_RATE);
              let hbdValue = hbdRate ? (hbdBalance * hbdRate) : (hbdBalance * PLACEHOLDER_HBD_RATE);
              let votingValue = hiveRate ? (votingPower * hiveRate) : (votingPower * PLACEHOLDER_HIVE_RATE);
              let accountBalance = hiveValue + hbdValue + votingValue;
              accountBalance = Math.round((accountBalance + 0.000001) * 1000) / 1000;
              this.setState({accountBalance: accountBalance.toLocaleString()})    
            })
            .catch(err => {
              
            })
        })
    }
    componentDidMount() {
      document.title = `${this.props.account}'s wallet`
      getAccounts([this.props.account])
      .then(this.handleResponse)
      .catch(err => {
        console.log(err);
      })
    }

    handleDonate = () => {
        this.setState({showDonatePopup: !this.state.showDonatePopup})
    }

    handleTransfer = () => {
        this.setState({showTransferPopup: !this.state.showTransferPopup})
    }

    render() {
        if (this.props.account === localStorage.getItem('userName')) {
            var donateButton = (
                <Button title='Cooming soon!' onClick={this.handleDonate}>Make a donation</Button>
            )

            var transferButton = (
                <Button title='Coming soon!' onClick={this.handleTransfer}>Transfer funds</Button>
            )
        }

        
        return (
            <div className='wallet-page padding-top'>
                <div className='wallet-container'>
                    <h1 className='wallet-header'>Wallet overview</h1>
                    <div className='wallet-user-avatar-container'>
                    <img className='wallet-user-avatar-image' src={`https://images.hive.blog/1024x1024/${this.state.profile_image}`} />
                    </div>
                    <h1>{this.props.account}</h1>
                    <div className='wallet-information-container'>
                      <h3>Estimated account value: ~${this.state.accountBalance}</h3>
                      <p>Hive: {this.state.balance}</p>
                      <p>Hive power: {this.state.votingPower}</p>
                      <p>Hive dollars: {this.state.hbd_balance}</p>
                    </div>
                    <div className='wallet-pay-buttons'>
                      {donateButton}
                      {transferButton}
                    </div>
                </div>
                <Modal show={this.state.showDonatePopup} onHide={this.handleDonate}>
                    <Modal.Header>
                        <Modal.Title>Donate coming soon!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        For now, you can use the Deegram app to donate
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleDonate}>Ok</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTransferPopup} onHide={this.handleTransfer}>
                    <Modal.Header>
                        <Modal.Title>Transactions coming soon!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleTransfer}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}