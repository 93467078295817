import React from 'react';
import Iframe from 'react-iframe';
import {Button} from 'react-bootstrap';
import { URL_REGISTER_LINK } from '../../constants';

//Brukeren kommer til denne siden når han velger "Registrer bruker". Her får bruker diverse informasjon, og en knapp som
//viser et Iframe overlay av Steem registreringsside når knappen trykkes.

export class HelpRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {ToRegistration: false};
    this.setRegistration = this.setRegistration.bind(this);
  }
    setRegistration() {
      this.setState({ToRegistration: true})
    }

render (){
    var regButton = (
      <Button
        type='button'
        href={URL_REGISTER_LINK}
      >
        Sign up
      </Button>)

    if (this.state.ToRegistration === true) {
    return(
     <div className= 'RegHelp padding-top'>
       <Iframe url="https://signup.steemit.com" //viser brukeren steem registrering via link
         width="100vw"
         height="100vh"
       />
     </div>
    )
    }
    else{
      return(
        <div className= 'padding-top'>
          <h1>Want to become a Deegram user?</h1>
          <p> Please click the button below</p>
          <br/>
          {regButton}
        </div>

      )
    }
  }
};
