import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, FormControl, Modal} from 'react-bootstrap';
import ContentPreviewItem from './contentPreviewItem';
import {getTrending} from '../blockchainApi/getTrending';
import {getHot} from '../blockchainApi/getHot';
import {getFeed} from '../blockchainApi/getFeed';
import {getDiscussionsByBlog} from '../blockchainApi/getDiscussionsByBlog';
import { getCreated } from '../blockchainApi/getCreated';
import { ErrorMessageView } from '../views/error/errorMessageView';
import photoMode from '../img/photomode.png';
import blogMode from '../img/blogmode.png';
import {hasVoted} from '../helpers/hasVoted';
import { getPayout } from '../helpers/getPayout';
import { getTrendingTags } from '../blockchainApi/getTrendingTags';
import { voteWeight, getUrlTag, URL_TAG, KEYWORD_FOR_DELETION } from '../constants';
import { ClipLoader } from 'react-spinners';
import { getUserBlackList } from '../deegramApi/getBlackList';

//Klasse som oppretter preview-feedene
//Henter innlegg basert på props gitt til klassen
export class GetFeedContent extends React.Component {
  constructor (props) {
    super(props);
    this.state ={
      contentFeed: [],
      tag: this.props.tag,
      match: this.props.match,
      loggedIn: JSON.parse(localStorage.getItem('loggedIn')),
      voteWeight: voteWeight,
      error: '',
      feedType: this.props.feedType,
      feedMode: this.props.feedOptions.feedMode,
      lastAuthor: '',
      lastPermlink: '',
      noPostsMessage: null,
      noMorePosts: null,
      chooseTagClicked: false,
      showMobileFeedType: false,
      showMobileFeedMode: false,
    }
  }

  /**
   * Removes deleted posts and posts from blacklisted users
   * @param {*} posts 
   */
  filterPosts = (posts) => {
    let blacklisted = getUserBlackList();
    try {
      let filtered = posts.filter(x => (x.title !== KEYWORD_FOR_DELETION && x.body !== KEYWORD_FOR_DELETION) && blacklisted.indexOf(x.author) < 0)
      return filtered;
    }
    catch (ex) {
      return posts;
    }
  }

  handleNoContentFound = () => {
    let typesToNotRefresh = ['new', 'personal', 'blog'];
    //tries to set feed to new (if applicaple) and refreshes feed
    if (typesToNotRefresh.indexOf(this.state.feedType) <= -1) {
      this.setState({feedType: 'new'}, () => {      
        this.getContent();
        this.props.callback(this.state.match.params.tag, 'new');
      });
    }
    //stops loading the feed if there are no more posts
    else {
      this.props.onNoContent((message) => {
        this.setState({noPostsMessage: message, feedLoading: false});
      });
    }
  }

  handleContent = (res) => {
    res = this.filterPosts(res);
    if (res.length === 0) {
      this.handleNoContentFound();
      return;
    }
    //stops loading feed if there is no more content
    if (res.length === 1) {
      //last post is not added to feed at first, added manually here if feed only contains one post
      if (this.state.contentFeed && this.state.contentFeed.length === 0) {
        let newContentFeed = [...this.state.contentFeed]
        newContentFeed = newContentFeed.concat(res);
        this.setState({contentFeed: newContentFeed})
      }
      this.setState({noMorePosts: true, feedLoading: false})
      return;
    }
    if (res.error) {
      this.setState({error: res.error.message});
    }
    //removes first post from result if there exists a last post in the feed - prevents duplicate posts
    if (this.state.lastAuthor)  {
      for (var i = 0; i < 1; i++ ) res.shift()
      //console.log(this.state.lastAuthor, this.state.lastPermlink)
    };
    //adds new result to old feed and updates last post
    let newContentFeed = [...this.state.contentFeed];
    newContentFeed = newContentFeed.concat(res);
    if (this.state.lastPermlink !== res[res.length-1].permlink) {
      var lastPost = res[res.length-1];
    }
    this.setLastPost(lastPost);
    this.setState({contentFeed: newContentFeed, feedLoading: false}); 
  }

  handleErrorContent = (err) => {
    this.setState({error: err.message, feedLoading: false})
  }

  getContent = () => {
    this.setState({feedLoading: true})
    //tag is used both as 'tag' on a post, used in most apis
    //also used as user when getting posts by a user, dont question the api
    let queryTag = '';
    if (this.state.match && this.state.match.params.tag) {
      queryTag = this.state.match.params.tag === '___' ? '' : this.state.match.params.tag;
    }
    var query = {
      tag: queryTag,
      start_author: this.state.lastAuthor ? this.state.lastAuthor : '',
      start_permlink: this.state.lastPermlink ? this.state.lastPermlink : '',    
      limit: 25 //careful changing this number, it can lead to feed consisting of duplicate posts
    };
    var blogQuery = {
      tag: this.props.tag,
      start_author: this.state.lastAuthor ? this.state.lastAuthor : '',
      start_permlink: this.state.lastPermlink ? this.state.lastPermlink : '',
      limit: 25
    };
    var feedQuery = {    
      tag: localStorage.getItem('userName'),
      start_author: this.state.lastAuthor ? this.state.lastAuthor : '',
      start_permlink: this.state.lastPermlink ? this.state.lastPermlink : '',
      limit: 25
    }
    if (this.state.feedType !== 'blog') {
      document.title = `${query.tag === '' ? 'all tags': query.tag} - ${this.state.feedType}`
    }
    switch(this.state.feedType) {          
      case 'new':
        getCreated(query)
        .then(this.handleContent)
        .catch(this.handleErrorContent)
        break;

      case 'trending':
        getTrending(query)
        .then(this.handleContent)
        .catch(this.handleErrorContent)
        break;

      case 'hot':
        getHot(query)
        .then(this.handleContent)
        .catch(this.handleErrorContent)
        break;
      
      case 'personal':
        getFeed(feedQuery)
        .then(this.handleContent)
        .catch(this.handleErrorContent)
        break;
      
      case 'blog': 
        getDiscussionsByBlog(blogQuery)
        .then(this.handleContent)
        .catch(this.handleErrorContent)
        break;

      default:
        document.title = 'Deegram';
        if (this.state.loggedIn && !query.tag) {
          getFeed(feedQuery)
          .then(this.handleContent)
          .catch(this.handleErrorContent)
        }
        else if (query.tag) {
          document.title = `${query.tag} - trending`
          getTrending(query)
          .then(this.handleContent)
          .catch(this.handleErrorContent)
        }
        else {
          let match = this.state.match;
          match.params.tag = 'deegram';
          this.setState({match: match })
          query.tag = 'deegram';
          getTrending(query)
          .then(this.handleContent)
          .catch(this.handleErrorContent)
        }
        break;
  }
  }

  initalizeComponent = () => {
    this.setState({lastAuthor: '', lastPermlink: '', contentFeed: []}, () => {
      this.getContent();
      this.props.callback(this.state.match.params.tag, this.state.feedType);
    })
  }

  componentDidUpdate(prevProps) {
    try {
      if ((this.props.tag !== prevProps.tag || this.props.match.params.tag !== prevProps.match.params.tag)
      || (this.props.feedType !== prevProps.feedType)) {
        this.initalizeComponent();
      }
    }
    catch (ex) {
      console.log('Failed to update component, missing props')
    }
  }

  setFeedMode = (feedMode) => {
    this.setState({feedMode: feedMode}); 
    localStorage.setItem('feedMode', feedMode);
  }

  componentDidMount() {
    this.initalizeComponent();
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const bottom = document.getElementById('feed-bottom');
    const hasReachedBottom = bottom.getBoundingClientRect().top <= window.innerHeight;
    if (!this.state.feedLoading && hasReachedBottom && !this.state.noMorePosts) {
      this.setState({feedLoading: true});
      this.getContent();
    }
  }

  setLastPost = (post) => {
    this.setState({
      lastAuthor: post ? post.author : '',
      lastPermlink: post ? post.permlink : ''
    })
  }

  onSubmit= (e) => {
    e.preventDefault();
  }

  toggleMobileFeedType = () => {
    this.setState({showMobileFeedType: !this.state.showMobileFeedType})
  }

  toggleMobileFeedMode = () => {
    this.setState({showMobileFeedMode: !this.state.showMobileFeedMode})
  }

  goToCustomtag = (e) => {
    document.location.href = getUrlTag(e.target[0].value)
    e.preventDefault();
  }

  render() {
    const feedLoaded = this.state.contentFeed.length > 0;
    const isProfileBlog = this.state.feedType === 'blog';

    if (this.state.noPostsMessage) {
      var noPostsMessage = (
        <>
        <div>{this.state.noPostsMessage}</div>
        {this.state.feedType !== 'new' && !isProfileBlog &&
          <Link to={getUrlTag(this.props.match.params.tag, 'new')}>Recent posts in this category</Link>}
        </>
      )
    }

    if (this.state.noMorePosts) {
      var noMorePosts = (
        <div>There is no more posts to be found in this category</div>
      )
    }

    if (this.state.feedLoading) {
      var feedLoadingSpinner = (
        <ClipLoader color={'#FF8D36'}/>
      )
    }

    //Oppretter ContentPreviewItem for hvert innlegg og gir den den
    //informasjonen den trenger.
    return(
      <div className={isProfileBlog ? 'feed-outer-container-profile' :'feed-outer-container'}>
        {this.state.error ? (<ErrorMessageView error={this.state.error}/>) : ''}
        <div className={(this.props.feedOptions.feedMode === 'blog' ? 'feed-grid-container-blog' : 'feed-grid-container')}>   
          {feedLoaded ? (this.state.contentFeed.map((feedItem, idx) =>
            <React.Fragment key={idx}>
              {(!isProfileBlog || !this.props.hideSharedPosts || this.props.tag === feedItem.author) &&
              <ContentPreviewItem
              key={idx}
                title = {feedItem.title}
                body = {feedItem.body}
                postUrl = {feedItem.url}
                author = {feedItem.author}
                permlink = {feedItem.permlink}
                parentPermlink = {feedItem.parent_permlink}
                created = {feedItem.created}
                jsonMetadata = {feedItem.json_metadata}
                netVotes = {feedItem.active_votes.length}
                activeVotes = {feedItem.active_votes}
                commentCount = {feedItem.children}
                payout = {getPayout(feedItem)}
                hasVoted = {hasVoted(feedItem.active_votes)}
                weight = {this.state.voteWeight}          
                profileReblogger = {isProfileBlog && this.props.tag !== feedItem.author? this.props.tag : undefined}  
                rebloggedBy = {feedItem.reblogged_by}/*isBlog && this.state.tag ? (this.state.tag !== feedItem.author ? [this.state.tag] : '') : feedItem.reblogged_by}*/
                feedMode = {this.props.feedOptions.feedMode}
              />}
            </React.Fragment> )): <div/>            
          }
          {feedLoadingSpinner}
          {noPostsMessage} {noMorePosts}
          <div id='feed-bottom' style={{margin: '0px 0px 0px 0px'}}></div>
        </div>
      </div>
      )
  }
}
