import hive from '@hiveio/hive-js';

/**
 * Returns the "trending" posts in a tag (use of no tag returns newest of ALL posts on the blockchain).
 * @param {{
 * tag: string,
 * limit: string | number,
 * start_author?: string,
 * start_permlink?: string
 * }} query Ex. format: {"tag":"bitcoin", "limit: 10"}
 */
export const getTrending = (query) =>{
  return new Promise((resolve, reject) => {  
    hive.api.getDiscussionsByTrending(
      query,
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}
