import hive from '@hiveio/hive-js';

//henter ett spesifikt innlegg fra hive og returnerer innholdet
/**
 * Returns a post on the hive blockchain.
 * @param {string} author author of the post
 * @param {string} permlink permlink of the post (on hive.blog: last part of url)
 */
export const getContent = (author, permlink) => {
  return new Promise((resolve, reject) => {
    hive.api.getContent(
      author,
      permlink,
      (err, res) => {
        if (err) {
          reject(err);
        }
        else if (res) {
          resolve(res)
        }
        else {
          reject();
        }
      }
    )
  })
}
