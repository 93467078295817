import React from 'react';
import {Link} from 'react-router-dom';
import {Modal, Button} from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import {getContentReplies} from '../blockchainApi/getContentReplies';
import {NoMatch} from '../routers';
import {votePost} from '../blockchainApi/votePost';
import commentIcon from '../img/comment.png';
import defaultAvatar from '../img/profile.png';
import { convertTime } from '../helpers/convertTime';
import { convertBody } from '../helpers/convertBody';
import { VoteButton } from '../items/voteButton';
import { getHiveAvatarImg, getUrlPost, getUrlProfile, KEYWORD_FOR_DELETION } from '../constants';
import { VotesOnPostView } from '../views/votesOnPostView';
import { postBlogForm } from '../blockchainApi/postBlogForm';
import { getTagsFromMetadata } from '../helpers/jsonMetadataParsers';

//Denne klassen viser previews av poster (som alle feed-sidene bruker)
export default class ContentPreviewItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      replyNumber: '',
      hasVoted: this.props.hasVoted,
      userName: localStorage.getItem('userName'),
      netVotes: this.props.netVotes,
      weight: this.props.weight,
      loading: false,
      feedMode: this.props.feedMode,
      viewVotes: false,
      voters: '',
      followingVotes: '',
      showDeletePopup: false,
      deleting: false,
      activeVotes: this.props.activeVotes ? this.props.activeVotes : [],
    };
    this.responseHandler = this.responseHandler.bind(this);
    this.handleVoteChanged = this.handleVoteChanged.bind(this);
    this.handleVoteSlider = this.handleVoteSlider.bind(this);
  }

  responseHandler(err, result){
    if(err){
       //NoMatch();
    } else if (result){
      //console.log('number of replies', result.length)
      this.setState({replyNumber: result.length})}
      this.checkVotes();
  };

  componentDidMount(){
    getContentReplies(this.props.author, this.props.permlink, this.responseHandler);
  }

  handleVoteSlider(weight) {
    //console.log(weight)
    this.setState({weight})
  }

  handleUpVotePost = (res) => {
    this.setState({activeVotes: res.activeVotes, hasVoted: res.hasVoted})
  }

  handleDownVotePost = () => {
    votePost(
      this.state.userName,
      this.props.author,
      this.props.permlink,
      -10000
    );
  }

  handleVoteChanged = (err, res) => {
    this.setState({hasVoted: res? !this.state.hasVoted: this.state.hasVoted});
  }

  toggleDeletePopup = () => {
    this.setState({showDeletePopup: !this.state.showDeletePopup})
  }

  markPostAsDeleted = () => {
    this.setState({deleting: true}, () => {
      postBlogForm('', this.props.parentPermlink, this.props.author, this.props.permlink, KEYWORD_FOR_DELETION, KEYWORD_FOR_DELETION, this.props.jsonMetadata)
      .then(permlink => {
        this.setState({postDeleted: true, deleting: false, deletedResultMessage: 'Post deleted!'}, () => {
          setTimeout(() => { document.location.reload() }, 3000)
        })
      })
      .catch(err => {
        this.setState({postDeletedFailed: true, deleting: false, deletedResultMessage: 'Could not delete post at this time. Please try again later.'});
        console.log('Error deleting post')
      })
    })
  }
  
  /**
   * Checks if votes contains any of people you follow ('friends')
   */
  checkVotes = () => {
    var friends = sessionStorage.getItem('followingList');
    if (friends) {
      friends = JSON.parse(friends);
      var friendVotes = [];
      for (var voter of this.state.activeVotes) {
        for (var friend of friends) {
          //does not add friend if they are author of post
          if (voter.voter === friend && voter.voter !== this.props.author) {
            friendVotes.push(friend);
            break;
          }
        }
      }
      if (friendVotes.length > 0) {
        var followingVotesString = `${friendVotes[0]}`;
        for (let i = 1; i < friendVotes.length && i < 3; i++) {
          followingVotesString += `, ${friendVotes[i]}`
        }        
        followingVotesString += ' voted on this'
        this.setState({followingVotes: friendVotes, followingVotesString: followingVotesString})
      }
    }
  }

  render () {
    
    if (this.props.title === KEYWORD_FOR_DELETION && this.props.body === KEYWORD_FOR_DELETION) return null;
    //console.log(this.props.body, this.props.jsonMetadata)

    var postData = {      
      author: this.props.author,
      permlink: this.props.permlink,
      root_author: this.props.author,
      root_permlink: this.props.permlink,
      title: this.props.title,
      created: this.props.created,
      body: this.props.body,
      hasVoted: this.state.hasVoted,
      activeVotes: this.state.activeVotes,
      netVotes: this.state.netVotes,                  
      commentCount: this.props.commentCount,
      payout: this.props.payout,
      tags: getTagsFromMetadata(this.props.jsonMetadata),
    }

    var showVideoPosts = true;
    if (this.props.rebloggedBy || this.props.profileReblogger) {
      var sharer = this.props.rebloggedBy ? this.props.rebloggedBy[0] : this.props.profileReblogger;
      var rebloggedBy = (
        <Link to={getUrlProfile(sharer)} className='preview-reblogged-by'>
          Shared by <img src={getHiveAvatarImg(sharer)} />{sharer}
        </Link>
      )
    }
    var postUrl = getUrlPost(this.props.author, this.props.permlink);
    var blogFeedMode = this.props.feedMode === 'blog';
    var body = this.props.body ? convertBody(this.props.body, true, false) : null;
    if (blogFeedMode) {
      var postBody = (        
        <div className='preview-blog-body'>
          <div className='preview-body'>
            {body}
          </div>
          <div className='preview-goto-fullpost'>
            <Link to={{
              pathname: postUrl, 
              state: {
                post: postData,
                }}}>Read more</Link>
            </div>
        </div>
      )
    }

    var imageUrl;
    var imageType = 'image';
    if(this.props.jsonMetadata){      
      var convertedMetadata = JSON.parse(this.props.jsonMetadata)
      var urlFirstInMarkdownApps = ['appics', 'ntopaz/ntopaz', 'dpoll/0.0.2'];
      if (urlFirstInMarkdownApps.indexOf(convertedMetadata.app) !== -1) {
        var urlStart = this.props.body.substring(this.props.body.indexOf("](")+2);
        var urlFirstInMarkdown = urlStart.substring(0, urlStart.indexOf(")"));
        imageUrl = urlFirstInMarkdown;
        imageType = 'image';
      }
      if (!imageUrl) {
        if (convertedMetadata.video && (convertedMetadata.video.videoId || convertedMetadata.video.info)) {
          //imageUrl = 'https://d.tube/#!/v/' + this.state.author + '/' + this.state.permlink;
          imageUrl = convertedMetadata.video.url;
          //console.log(convertedMetadata)
          imageType = 'video';
        }
      }
      if (!imageUrl) {
        //checks all images in jsonmetadata, exits loops if an url is found. Need to check if urls work though
        var urlInMetadata = '';
        if (convertedMetadata.image) {
          for (var i = 0; i < convertedMetadata.image.length; i++) {
            if (urlInMetadata.length > 5) break;
            urlInMetadata = convertedMetadata.image[i];
          }
        }
        if (urlInMetadata.length > 5) imageUrl = urlInMetadata;   
      }
      if (!imageUrl) {
        const imageLinkRegex = /https*:\/\/((deegram(-test)*\.azurewebsites\.net\/api\/images\/\w+)|(([\w\d\.\-_\/:%]+\.(jpg|png|gif))))/g;
        let match = this.props.body.match(imageLinkRegex);
        if (match) {
          imageUrl = match;
        } 
      }
    }
    var imageClassName = (imageType === 'video' ? 'preview-image-video' : 'preview-image');
    imageClassName += blogFeedMode ? '-blog' : '';
    if (imageType === 'image') {
      var postImage = (
        <img className={imageClassName} src={imageUrl}/>
      )

      //renders the image container if an url was found
      if (imageUrl) {
        var postImageContainer = (
          <div className={blogFeedMode ? 'preview-image-container-blog' : 'preview-image-container'}>
            <Link to={{
                pathname: postUrl, 
                state: postData,
            }}>
              {postImage}
            </Link>
          </div>
        )
      }
    }
    else if (imageType === 'video') {
      if (!showVideoPosts) {
        return null;
      }
      postImage = (
        //<a href={imageUrl}></a>
        <iframe src={`https://www.youtube.com/embed/${convertedMetadata.video.videoId}`} frameBorder="0" 
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen 
        title={`Video ${this.props.author}/${this.props.permlink}`} id='iframe' 
        className={blogFeedMode ? 'preview-video-blog' : 'preview-video'}/>
      )

      var postImageContainer = (
        <div className={blogFeedMode ? 'preview-video-container-blog' : 'preview-video-container'}>
          {postImage}
        </div>
      )
    }

    if (this.state.showDeletePopup) {
      var deletePopup = (
        <Modal show={this.state.showDeletePopup} onHide={this.toggleDeletePopup}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you wish to delete this post?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This will remove the post from the Deegram platform.
            <br/>
            Please be aware that the post history may still be available elsewhere.
            <br/>
            <div className='delete-post-popup-spinner'></div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.deleting ? (<ClipLoader size={40} css={'border-width: 3px !important;'}/>) : (
              this.state.deletedResultMessage ? <p>{this.state.deletedResultMessage}</p> : (
                <>
                <Button onClick={this.toggleDeletePopup}>Cancel</Button>
                <Button onClick={this.markPostAsDeleted}>Confirm deletion</Button>
                </>)
            )}
          </Modal.Footer>
        </Modal>
      )
    }

    var voteData = {
      weight: this.state.weight,
      voter: this.state.userName,
      author: this.props.author,
      permlink: this.props.permlink,
      activeVotes: this.state.activeVotes,
      hasVoted: this.state.hasVoted
    }

    // må legge inn sjekk for om state er tom. kan trynet hvis ikke det er det
    return(
      <div className='grid-container-feed-object' id={`${this.props.author}_${this.props.permlink}`}>
        <div className='preview-top-bar'>
          <div className='preview-author'>      
            <Link className='preview-author-img' to={getUrlProfile(this.props.author)}>
              <img src={this.props.author ? (getHiveAvatarImg(this.props.author)) : defaultAvatar}/>
            </Link>
            <Link className='preview-author-text' to={getUrlProfile(this.props.author)}>{this.props.author}</Link>
            <div className='preview-created'>
              <p>{convertTime(this.props.created)}</p>
            </div>        
          </div>
          {rebloggedBy}
          {this.state.userName === this.props.author ? <div className='preview-delete-post'>
            <Button onClick={this.toggleDeletePopup} className='preview-delete-post-button'>Delete</Button>
            </div> : ''}
        </div>
        <div className='preview-title'>
          <Link className='preview-title-text' to={{
              pathname: postUrl, 
              state: {
                post: postData,
                }}}>{this.props.title}</Link>
        </div>
        {postImageContainer}
        <div className='preview-bottom-bar'>          
          <div className='preview-votes'>      
            <VoteButton className='preview-vote-item' 
            voteData={voteData}
            loading={this.state.loading} 
            handleUpVotePost={this.handleUpVotePost}
            onChange={this.handleVoteSlider}
            voteError={this.state.voteError}/>
            <p className='preview-following-votes'>{this.state.followingVotesString}</p>        
          </div>
          <p className='preview-comments'>
            <Link className='preview-comments-text' to={{
              pathname: postUrl, 
              hash: '#comments',
              state: {
                toComments: true,
                post: postData,
                }}}>
              <img className='preview-comment-icon' src={commentIcon} alt='comment icon' />
              {this.props.commentCount}
            </Link>
          </p>
          <p className='preview-payout'>${this.props.payout ? this.props.payout.slice(0,-5): ' 0.00'}</p>
        </div>
        {postBody}
        {deletePopup}
      </div>
    )
  }
}
