import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Popover} from 'react-bootstrap';
import { getNotifications } from '../../blockchainApi/getNotifications';
import {convertTime} from '../../helpers/convertTime';
import { PulseLoader } from 'react-spinners';
import { getHiveAvatarImg, URL_POST } from '../../constants';
import './notification.css';

export class NotificationWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsPerPage: 100,
      allNotifications: [],
      notifications: [],
      loading: true,
      fetchedAll: false,
      lastNotification: -1,
    }
  }

  //fetches notifications starting at the newest
  fetchNotifications = (from = this.state.lastNotification, amountFetched = 0) => {
    //exits recursion if there are no more notifications
    if (this.state.fetchedAll) return;

    var limit = this.state.notificationsPerPage;
    //sets the new fetch start from 1 before last notification fetched
    from = (from > 0) ? from - 1 : from;
    //if limit is greater than starting position, set limit to start position 
    limit = (limit > from && from !== -1) ? from : limit;
    getNotifications(from, limit)
    .then(res => {
      let notifications = res.notifications;
      let lastNotification = res.lastNotification;
        this.setState({
          notifications: this.state.notifications ? this.state.notifications.concat(notifications) : notifications,
          lastNotification: lastNotification,
        }, () => {
          //leaves recursion because all notifications have been fetched at this point
          if (lastNotification === 0) {
            this.setState({fetchedAll: true, loading: false})
            return;
          }
          amountFetched += notifications.length;
          //ensures a fetch gets at least 5 new visible notifications
          if (amountFetched < 5) {
            this.fetchNotifications(lastNotification, amountFetched);
          }
          else {
            this.setState({loading: false})
          }
        });
    })
    .catch(err => {
      //console.log(err);
      this.setState({loading: false})
    })

  }

  handleKeyDown = (e) => {
    try {
      if (e.keyCode === 27) {
        this.props.onEscapePressed();
      }
    }
    catch (ex) {

    }
  }

  isBottom = (element) => {
    var container = document.getElementById('notifications-container').getBoundingClientRect();
    return element.getBoundingClientRect().top <= container.bottom;
  }

  componentDidMount() {
    this.setState({loading: true}, () => {
      this.fetchNotifications()
    })
    document.getElementById('notifications-container').addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.getElementById('notifications-container').removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('notifications-bottom-window');
    if (this.isBottom(wrappedElement) && !this.state.loading) {
      this.setState({loading: true}, this.fetchNotifications);
    }
  }

  clickFetch = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }


  render() {
    if (!this.state.notifications) return null;

    if (this.state.loading && !this.state.fetchedAll) {
      var scrollToLoad = (
        <PulseLoader />
      )
    }
    else if (!this.state.fetchedAll) {
      var scrollToLoad = (
        <div className='notifications-scroll-message'><Button onClick={this.clickFetch}>
          Scroll down to load more...
        </Button></div>
      )
    }
    return (
      <div className='notifications-container' id='notifications-container' onKeyDown={this.handleKeyDown}>
        {this.state.notifications.map((notification, index) =>  
        <Link key={index} to={notification.href}>    
          <div className={`notification-single-container`}>
            <img className='notification-user-avatar' src={getHiveAvatarImg(notification.user)} />
            <p className='notification-message'>{notification.user} {notification.message}</p>
            <p className='notification-timestamp'>{convertTime(notification.timestamp)}</p>
          </div>
          </Link>
        )}
        {this.state.fetchedAll && (<div>No more notifications</div>)}
        <div id='notifications-bottom-window'>{scrollToLoad}</div>
      </div>
    )
  }
}