import hive from '@hiveio/hive-js';

/**
 * 
 * @param {string} account 
 * @param {string | number} start 
 * @param {string | number} limit 
 */
export const getAccountHistory = (account, start, limit) => {
  return new Promise((resolve, reject) => {
    hive.api.getAccountHistory(account, start, limit, (err, result) => {
      if (result) {
        resolve(result)
      }
      else {
        reject(err)
      }
    })
  })
}