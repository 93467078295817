import React from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Button, FormControl} from 'react-bootstrap';
import {lookupAccounts} from '../blockchainApi/lookupAccounts';
import {getUrlSearch, getUrlProfile} from '../constants';
import { getFollowing } from '../blockchainApi/getFollowing';
import { getFollowers } from '../blockchainApi/getFollowers';

export class SearchForUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      searchResults: [],
      selectedQuery: -1,
      maxResults: 5,
    };
  }
  
  updateSearchQuery = (e) => {
    let searchQuery = e.target.value.toLowerCase();
    this.setState({searchQuery: searchQuery}, () => {this.getSearchResults(this.state.searchQuery)});
  } 

  getSearchResults = (query) => {
    let loggedIn = localStorage.getItem("loggedIn")
    let userName = localStorage.getItem("userName")
    if (loggedIn && JSON.parse(loggedIn) && userName) {    
      getFollowing(userName)
      .then(res => {
        return new Promise((resolve, reject) => {
          let matchedFollowing = []
          for (var r of res) {
            if (r.following.includes(query)) {
              matchedFollowing.push(r.following)
            }
          }
          resolve(matchedFollowing)
        })
      })
      .then(searchResults => {
        return new Promise((resolve, reject) => {
          getFollowers(userName)
          .then(res => {
            for (var r of res) {
              if (r.follower.includes(query)) {
                searchResults.push(r.follower)
              }
            }
            resolve(searchResults)
          })
        })
      })
      .then(searchResults => {
        lookupAccounts(query, this.state.maxResults)
        .then(res => {
          for (var user of res) {
            if (!searchResults.includes(user)) {
              searchResults.push(user)
            }
          }
          for (var foundUser of searchResults) {          
            if (foundUser === query) {
              let exactMatchPosition = searchResults.indexOf(query)
              if (exactMatchPosition > -1) {
                searchResults.splice(exactMatchPosition, 1);
                searchResults.unshift(query)
              }
              break;
            }
          }
          if (this.state.searchResults !== searchResults) {
            this.setState({searchResults: query ? searchResults : ''})
          }
        })
      })
    }
    else {
      lookupAccounts(query, this.state.maxResults)
      .then(res => {
        if (this.state.searchResults !== res) {
          this.setState({searchResults: query ? res : ''});
        }
      })
    }
    /*
    lookupAccounts(query, this.state.maxResults)
    .then(res => {  
      if (this.state.searchResults !== res) {
        this.setState({searchResults: query ? res : ''});
      }
    })
    .catch(err => {

    })*/
  }

  submitSearch = (e) => {
    if (this.state.selectedQuery < 0) { 
      //none of the results in the dropdown is marked
      document.location.href = getUrlSearch(this.state.searchQuery);
    }
    else {
      //one of the results in the dropdown is marked
      var profileToVisit = this.state.searchResults[this.state.selectedQuery];
      document.location.href = getUrlProfile(profileToVisit);
    }
    e.preventDefault();
  }

  hideSearchResults = () => {
    this.setState({searchResults: []})
  }

  onBlur = (e) => {
    setTimeout(this.hideSearchResults, 500);
  }

  clearSearch = (e) => {
    e.preventDefault();
    e.target.value = '';
    this.updateSearchQuery(e);
  }
  
  /**
   * Instructions for components when certain keys are pressed down
   * @param {*} e key down event
   */
  handleKeyDown = (e) => {
    try {
    switch(e.keyCode) {
      //down arrow pressed - returns -1(unselect) if at end, else increments by 1
      case 40:
        this.setState(prevState => {
          if (prevState.selectedQuery === this.state.maxResults -1) {
            return {selectedQuery: -1};
          }
          return {selectedQuery: prevState.selectedQuery +1};
        })        
        //prevents jumping to query end
        e.preventDefault();
        break;

      //up arrow pressed - returns end if at -1(unselect), else decrements by 1
      case 38:
        //console.log(this.state.selectedQuery);
        this.setState(prevState => {
          if (prevState.selectedQuery <= -1) {
            return {selectedQuery: this.state.maxResults -1};
          }
          return {selectedQuery: prevState.selectedQuery -1};
        })
        //prevents jumping to query start
        e.preventDefault();
        break;

      //page up - move to top
      case 33:
        e.preventDefault();
        this.setState({selectedQuery: 0})
        break;

      //page down - move to bottom
      case 34:
        e.preventDefault();
        this.setState({selectedQuery: this.state.maxResults -1})
        break;

      //esc - close window
      case 27:
        e.preventDefault();
        if (this.state.searchResults && this.state.searchResults.length > 0) {
          this.hideSearchResults();
        }
        else {
          this.setState({searchResults: this.getSearchResults(this.state.searchQuery)})
        }
        break;

      default:
        //ignore
        break;
    }
    }
    catch (ex) {
      console.error(ex);
    }
  }


  render() {
    var searchInput = (
      <FormControl className='navbar-search-input'  ref='searchInput' name='account' type="text" 
      placeholder="Search for a profile..." onChange={this.updateSearchQuery} onKeyDown={this.handleKeyDown} 
      onFocus={() => {
        if (this.state.searchQuery) {
          this.getSearchResults(this.state.searchQuery);
        }
      }} onBlur={this.onBlur}
      autoComplete='off' required style={{height: 'inherit'}} 
      /*height:inherit must be inline style, does not work when set in a css class. Not sure why*/
      />
    )
    //returns truthy even with result length 0
    if (this.state.searchResults && this.state.searchResults.length > 0) {
      var resultDropdown = (
        <div className='navbar-search-results-container'>
          <ul className='navbar-search-results'>
          {this.state.searchResults.slice(0, this.state.maxResults).map((account, index) => 
            <Link key={index} to={getUrlProfile(account)} 
            className={'navbar-search-profile-link ' + (index === this.state.selectedQuery ? ' query-selected' :'')}>
              <li>{account}</li>
            </Link>
          )}
          </ul>
        </div>
      )
      /*
      var clearSearchButton = (        
        <Button className='navbar-clear-search' onClick={this.clearSearch} title='Clear search'>x</Button>
      )*/
    }
    return (
      <Form className='navbar-item navbar-search-input-container' onSubmit={this.submitSearch}>
        <FormGroup>          
          {searchInput}
          {resultDropdown}
        </FormGroup>
        <Button className='navbar-search-button' type="submit">Search</Button>
      </Form>
    )
  }
}