import React from 'react'
import { MainNavBar } from './navbar/mainNavBar';
import { BrowserRouter } from 'react-router-dom';
import './styleForMobile.css';

export class Main extends React.Component {
  render() {
    return (
      <div className='main'>
        <BrowserRouter>
          <MainNavBar />
        </BrowserRouter>
      </div>
    )
  }
}
