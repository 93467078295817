/**
 * 
 * @param {{}} hivePost 
 */
export const getPayout = (hivePost) => {
    try {
    if (!hivePost || hivePost.length === 0) return '0.000 HBD';
    var veryEarlyDate = new Date(2000,1,1);
    var payoutDate = new Date(hivePost.last_payout)
    //payoutDate is 01/01/1970 if rewards have not been claimed
    if (payoutDate > veryEarlyDate) {
        return hivePost.total_payout_value ? hivePost.total_payout_value : '0.000 HBD';
    }
    else {
        return hivePost.pending_payout_value ? hivePost.pending_payout_value : '0.000 HBD';
    }
    }
    catch (ex) {
        return '0.000 HBD';
    }
}