import hive from '@hiveio/hive-js';

/**
 * Returns the top-level replies to a post. Replies are treated as a post of their own,
 * and a reply's permlink can be used on this api (or others) to get more information
 * @param {string} author author of the post.
 * @param {string} permlink permlink of the post.
 */
export const getContentReplies = (author, permlink) =>{
  return new Promise((resolve, reject) => {
    hive.api.getContentReplies(
      author,
      permlink,
      (err, res) => {
        if (res) {
          resolve(res)
        }
        else {
          reject(err);
        }
      }
    )
  })
}
