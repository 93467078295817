import React from 'react';
import {Link} from 'react-router-dom';
import {getTagsFromMetadata} from '../helpers/jsonMetadataParsers';
import {NoMatch} from '../routers'
import { convertBody } from '../helpers/convertBody';
import { VoteButton } from '../items/voteButton';
import { hasVoted } from '../helpers/hasVoted';
import {voteWeight, getUrlPost, getUrlProfile, getHiveAvatarImg, getUrlTag} from '../constants';
import {PostCommentView} from '../pages/fullPost/postCommentView';
import { ErrorMessageView } from '../views/error/errorMessageView';
import { convertTime } from '../helpers/convertTime';

//Klassen brukes for å vise en gitt post

export class FullPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: localStorage.getItem('userName'),
      voteError: '',
      commentError: '',
      error: '',
      viewVotes: false,
     }
     this.handleContentResponse = this.handleContentResponse.bind(this);
  }

  handleContentResponse(err, result){
    if(err){
      this.setState({error: err})
       //NoMatch();
    } else if(result.author === '') {
      document.location.href = '/404'
    } else {
    let jsonTags = getTagsFromMetadata(result.json_metadata)
    this.setState({
      post: result,
      tags: jsonTags,
      hasVoted: hasVoted(result.active_votes),
      activeVotes: result.active_votes,
      netVotes: result.active_votes.length,
    }, () => {
      try {
        this.tryCenteringImages(0);        
      }
      catch (ex) {
        this.tryCenteringImages(0);
      }
    })
    }
  }

  /**
   * Centers the images in the post by looking for html img tags in the post body
   */
  tryCenteringImages = (attempts = 0) => {
    try {
      if (attempts >= 3) return;
      let images = document.getElementsByClassName('readpost-body')[0].getElementsByTagName('img');
      let imageCounter = 0;
      let interval = setInterval( () => {
        //exits interval (loop) if all images are centered
        //TODO: exit at some point regardless (edge case: some image wont give their width for some reason)
        if (imageCounter >= images.length) {
          clearInterval(interval);
        }
        for (let image of images) {
          let width = image.getBoundingClientRect().width;
          if (!width) continue;
          else if (width) {
            image.style.margin = `5px calc(50% - ${image.getBoundingClientRect().width}px / 2)`;
            imageCounter++;
          }
        }     
        //interval time is 100ms   
      }, 100);
    }
    //can happen if post hasn't loaded yet for example, tries again after some time
    catch (ex) {
      setTimeout(() => {
        this.tryCenteringImages(attempts+1)
      }, 50)
    }
  }

  viewVotes = () => {
    this.setState({viewVotes: !this.state.viewVotes})
  }

  handleResize = () => {
    this.setState({windowHeight: window.innerHeight, windowWidth: window.innerWidth})
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  componentDidMount(){
    this.tryCenteringImages(0);
  }

  handleUpVotePost = (res) => {
    this.props.handleUpVotePost(res);
  }

  render (){
    if (!this.props.post) {
      return null;
    }
    var renderedBody = convertBody(this.props.post.body);

    if (JSON.parse(localStorage.getItem('loggedIn'))) {
      var replyButton = (
        <PostCommentView
          className='post-reply-button'
          onSubmit={this.props.handleSubmitComment}
          userName={this.state.userName}
          parentAuthor={this.props.post.author}
          parentPermlink={this.props.post.permlink}
          commentError={this.props.commentError}
        />)
    }

    var voteData = {      
      weight: this.state.weight,
      voter: this.state.userName,
      author: this.props.post.author,
      permlink: this.props.post.permlink,
      activeVotes: this.props.post.activeVotes,
      hasVoted: this.props.post.hasVoted
    }
    var voteButton = (      
      <VoteButton className='preview-vote-item' 
      voteData={voteData}
      loading={this.state.loading} 
      onChange={this.handleVoteSlider}
      handleUpVotePost={this.handleUpVotePost}
      voteError={this.state.voteError}/>
    )

    if (this.props.post.root_author !== this.props.post.author ||
       this.props.post.permlink !== this.props.post.root_permlink) {
      var postIsReply = (
        <div className='readpost-post-is-reply'>
          <Link to={getUrlPost(this.props.post.root_author, this.props.post.root_permlink)}>
            This is a reply to a post, click here to see the original post
          </Link>
        </div>
      )
    }
    this.tryCenteringImages(0);
    return (
        <div>
          {this.state.error && (<ErrorMessageView error={this.state.error} />)}
          <div className='readpost-container'>
            {postIsReply}
            <div className='readpost-title'>
              <h1>{this.props.post.title}</h1>
              <div className='readpost-author-line' >
                <Link to={getUrlProfile(this.props.post.author)}>
                  <img className='readpost-author-avatar' src={getHiveAvatarImg(this.props.post.author)} />
                  </Link>
                <Link to={getUrlProfile(this.props.post.author)}><h3 className='readpost-author-name'>{this.props.post.author}</h3></Link>
                <h3 className='readpost-timestamp'>{convertTime(this.props.post.created)}</h3>
              </div>
            </div>
            <div className='readpost-body' >  
              <hr style={{backgroundColor: 'black', height: '1px', width: '100%'}}/>
              {renderedBody}
            </div>
            <div className='readpost-tags' >
              {this.props.post.tags && this.props.post.tags.map((tag, idx) =>
                <Link key={idx} className='readpost-tag'
                to={getUrlTag(tag)}>#{tag}</Link>)}
            </div>
            {!this.props.isPreview && (
              <>
            <div className='readpost-bottom-bar'>
              <div className='readpost-votes'>
                {voteButton} 
              </div>              
              <div className='readpost-payout-value'>${this.props.post.payout ? this.props.post.payout.slice(0,-5) : ' 0.00'}</div>
            </div>
            {replyButton}
            </>)}
          </div>
        </div>
    )
  }
}

  FullPost.defaultProps = {
    match: {params: {author: 'author', permlink: 'permlink'}},
    author: 'author',
    permlink: 'permlink'
  }
