//node_modules
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Navbar, Nav, NavItem, Container, NavDropdown, FormControl, Button, Form, Modal } from 'react-bootstrap';

//api
import { getFollowing } from '../blockchainApi/getFollowing';
import { getTrendingTags } from '../blockchainApi/getTrendingTags';
import { hivesignerClient } from '../blockchainApi/hivesignerApi';
import { logoutUser } from '../blockchainApi/logout';

//other classes
import { MainRouter } from '../routers';
import { isAuthenticated } from '../authenticator';
import { NotificationWindow } from '../pages/notifications/notificationWindow';
import { getUrlTag, URL_NEW_POST, URL_SETTINGS, URL_PROFILE, URL_REGISTER_LINK, getHiveAvatarImg, getUrlProfile, getUrlWallet, URL_SEARCH, URL_NOTIFICATIONS, URL_FEEDBACK_FORM, URL_APP_LINKS } from '../constants';
import { SearchForUser } from '../search/searchForUser';

//img
import AppStoreIcon from '../img/App_Store_iOS_11.png';
import Logo from '../img/logo.png';
import NotificationIcon from '../img/notification_off.png';

//css
import './mainNavBar.css';
/**
 * Navigation bar present on all pages. Authentication is handled in this class
 * (as well as authenticator.js)
 */
export class MainNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: localStorage.getItem('loggedIn'),
      userName: localStorage.getItem('userName'),
      followingList: '',
      showNotifications: false,
      displayAppPopup: false,
      hasDeclinedApp: false,
      chooseTagClicked: false,
      chooseTagList: '',
      feedOptions: {
        tag: '',
        feedType: '',
        feedMode: (localStorage.getItem('feedMode')) ? (localStorage.getItem('feedMode')) : 'image',
      }
    };
    this.cookies = new Cookies();
    //sets title of the web browser tab
    //TODO: use react-helmet to set title?
    document.title = 'Deegram';
  }

  componentDidMount() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      console.log('Using a mobile client');
      this.setState({ displayAppPopup: true, isMobile: true })
    }

    isAuthenticated()
      .then(res => this.setLoggedIn(res))
      .catch(err => this.setState({ sessionExpired: true }));

    this.setState({ cookiesEnabled: this.cookies.get('cookiesEnabled') })
    if (this.state.isLoggedIn) {
      var friends = sessionStorage.getItem('followingList');
      if (friends) {
        this.setState({ followingList: JSON.parse(friends) })
      }
      else {
        this.setFollowingList();
      }
    }
    this.setState({ hasDeclinedApp: this.cookies.get('hasDeclinedApp') })
  }

  setFollowingList = () => {
    getFollowing(this.state.userName, (err, res) => {
      if (res && res.length > 0) {
        var friends = [];
        for (var connection of res) {
          if (connection && connection.following !== this.state.userName) {
            friends.push(connection.following);
          }
        }
        this.setState({ followingList: friends });
        sessionStorage.setItem('followingList', JSON.stringify(friends));
      }
    })
  }

  setLoggedIn = (res) => {
    localStorage.setItem('userName', res.user);
    localStorage.setItem('loggedIn', true);
    this.setState({ isLoggedIn: true, userName: res.user })
    //redirect to current page, removes accessToken from url
    var redirectUrl = sessionStorage.getItem('currentUrl');
    if (redirectUrl) {
      sessionStorage.removeItem('currentUrl');
      //document.location.href = redirectUrl;
    }
  }

  /**
   * Saves the current web url, then redirects to login url.
   * User is redirected to current url after logging in.
   */
  goToLogin = () => {
    if (this.state.cookiesEnabled) {
      sessionStorage.setItem('currentUrl', document.location.href);
      document.location.href = hivesignerClient.getLoginURL();
    }
    else {
      this.setState({ showCookieWarning: true })
    }
  }

  hideCookieWarning = () => {
    this.setState({ showCookieWarning: false })
  }

  //Bruker denne som callback i logoutUser
  setLoggedOut = () => {
    this.setState({ isLoggedIn: false, userName: '' }, () => {
      var url = sessionStorage.getItem('currentUrl');
      if (url) {
        document.location.href = url;
      }
      else {
        document.location.href = '/';
      }
    })
  }

  handleLogOut = () => {
    sessionStorage.setItem('currentUrl', document.location.href);
    logoutUser(this.setLoggedOut);
  }

  enableCookies = () => {
    return new Promise((resolve, reject) => {
      try {
        this.cookies.set('cookiesEnabled', true, { path: '/' });
        this.setState({ cookiesEnabled: 'true' }, () => {
          resolve();
        })
      }
      catch (e) {
        reject(e);
      }
    })
  }

  acceptCookies = () => {
    this.enableCookies()
      .then((res) => this.goToLogin())
      .catch(err => console.log(err));
  }

  showNotifications = () => {
    this.setState({ showNotifications: !this.state.showNotifications })
  }

  hideGetAppPopup = () => {
    let el = document.getElementById('mobile-popup-dont-ask-again');
    if (el && el.checked) {
      this.cookies.set('hasDeclinedApp', true);
    }
    else {
      var date = new Date();
      date = new Date(date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000)));
      this.cookies.set('hasDeclinedApp', true, { expires: date })
    }
    localStorage.removeItem('hasDeclinedApp');
    this.setState({ displayAppPopup: false, hasDeclinedApp: 'true' })
  }

  closeTagList = () => {
    this.setState({ showTagsList: false })
  }

  openTagList = () => {
    if (this.state.chooseTagList) {
      this.setState({ showTagsList: true })
    }
    else {
      getTrendingTags('', 20)
        .then(res => {
          this.setState({ showTagsList: true, chooseTagList: res })
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  goToCustomtag = (e) => {
    try {
      document.location.href = getUrlTag(e.target[0].value);
    }
    catch (e) {

    }
    e.preventDefault();
  }

  closeSessionExpired = () => {
    this.setState({ sessionExpired: false }, () => {
      console.log(this.state.sessionExpired);
      window.location.reload()
    })
  }

  setFeedMode = (mode = 'image') => {
    var options = this.state.feedOptions;
    options.feedMode = mode;
    this.setState({ feedOptions: options });
    localStorage.setItem('feedMode', mode);
  }

  returnUrl = (tag, type) => {
    if (this.state.isLoggedIn && !tag) {
      let options = {
        tag: '',
        feedType: type ? type : '',
        feedMode: this.state.feedOptions.feedMode
      }
      this.setState({ feedOptions: options })
    }
    else {
      let options = {
        tag: tag ? tag : '',
        feedType: type ? type : 'trending',
        feedMode: this.state.feedOptions.feedMode
      }
      this.setState({ feedOptions: options })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.feedOptions.tag !== prevState.feedOptions.tag) {
      this.setState({ showTagsList: false })
    }
  }

  render() {
    if (this.state.isLoggedIn) {
      //button link to create post page
      var createPostButton = (
        <Link className='btn btn-default navbar-item' to={URL_NEW_POST}>
          New post
        </Link>
      )

      if (this.state.showNotifications) {
        var notificationWindow = (
          <NotificationWindow onEscapePressed={this.showNotifications} />
        )
      }

      //notification icon that brings up user's notifications
      var notificationsButton = (
        <NavItem className={`navbar-text-button notifications-button ${this.state.showNotifications ? 'selected' : ''}`}
          onClick={this.showNotifications} eventKey={4.3}>
          <img className='navbar-notification-icon' src={NotificationIcon} />
          {notificationWindow}
        </NavItem>
      )

      //text button that takes user to their feed 
      var myFeedButton = (
        <li className='navbar-text-button my-feed-button'>
          <NavLink to='/feed' activeClassName='selected'>My feed</NavLink>
        </li>
      )

      //text button that takes user to their profile
      var myProfileButton = (
        <li className='navbar-text-button my-feed-button'>
          <NavLink to={getUrlProfile(this.state.userName)} activeClassName='selected'>My profile</NavLink>
        </li>
      )

      //user's profile picture, displayed on drop-down button
      var profileAvatar = (
        <img className='profile-picture-navbar'
          src={getHiveAvatarImg(this.state.userName)}
          alt='avtr' />
      )
      /* drop-down items start */
      //link to the user's profile
      var profileItem = (
        <li><NavLink to={getUrlProfile(this.state.userName)}>My Profile</NavLink></li>
      )
      //link to user's wallet
      var walletButton = (
        <li><NavLink to={getUrlWallet(this.state.userName)}>My Wallet</NavLink></li>
      )
      //links to user's notifications
      var notifications = (
        <li><NavLink to={URL_NOTIFICATIONS}>Notifications</NavLink></li>
      )
      //revokes user's hivesigner accesstoken
      var logoutButton = (
        <li><a style={{ cursor: 'pointer' }} onClick={this.handleLogOut}>
          Log out
        </a></li>
      )

      /* drop-down items end */
      var mobileDropdownLoggedIn = (
        <>
          <NavLink to='/feed' activeClassName='current-nav'>
            My feed
          </NavLink>
          <NavLink to={URL_NOTIFICATIONS} activeClassName='current-nav'>
            Notifications
          </NavLink>
          <NavLink to={getUrlProfile(this.state.userName)} activeClassName='current-nav'>
            My profile
          </NavLink>
          <NavLink to='/discover' activeClassName='current-nav'>
            Discover
          </NavLink>
        </>
      )
    }
    else { //not logged in
      //refers user to sign-up form
      var registerButton = (
        <Button
          type='button'
          eventkey={1.1}
          href={URL_REGISTER_LINK}
          target='_blank'
        >
          Register now!
        </Button>
      )
      //sends user to log in with hivesigner
      var loginButton = (
        <Button
          eventkey={1.2}
          onClick={this.goToLogin}
        >
          Log in
        </Button>
      )

      var mobiledropdownNotLoggedIn = (
        <>
          <NavItem onClick={this.goToLogin}>Log in</NavItem>
          <NavItem href={URL_REGISTER_LINK}>Register</NavItem>
        </>
      )
    }


    if (this.state.isMobile) {
      var mobileDropdown = (
        <Navbar.Collapse className='nav-mobile-dropdown'>
          <Nav>
            {!this.state.isLoggedIn && (mobiledropdownNotLoggedIn)}
            <NavLink to={URL_SEARCH} activeClassName='current-nav'>
              User search
            </NavLink>
            {mobileDropdownLoggedIn}
            <NavLink to={URL_SETTINGS} activeClassName='current-nav'>
              Settings
            </NavLink>
            {/*TODO put feedback in iframe? */}
            <NavItem href={URL_FEEDBACK_FORM} activeClassName='current-nav'>
              Give feedback
            </NavItem>
            <NavLink to={URL_APP_LINKS} activeClassName='current-nav'>
              Download app
            </NavLink>
            {this.state.isLoggedIn && (
              <NavItem onClick={this.handleLogOut} style={{ gridColumn: '1 / span 2', gridRow: '5' }}>
                Log out
              </NavItem>
            )}
          </Nav>
        </Navbar.Collapse>
      )
    }



    if (this.state.showTagsList) {
      let currentFeedType = 'trending';
      if (this.state.feedOptions && this.state.feedOptions.feedType !== 'blog') {
        currentFeedType = this.state.feedOptions.feedType;
      }
      var tagList = (
        <Modal show={this.state.showTagsList} onHide={this.closeTagList} className='feed-select-tag-popup'>
          <Modal.Header closeButton>
            <Modal.Title>
              Select a tag
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='feed-select-tag-popup-body'>
            <NavLink activeClassName='btn-selected' id='feed_tag' className='btn btn-default' to={'/feed'} title='View personal feed'>Home feed</NavLink>
            <Link id='tag_' className='btn btn-default' to={`/all-tags/${currentFeedType}`} title='View posts from all of hive'>all tags</Link>
            <NavLink activeClassName='btn-selected' id='tag_deegram' className='btn btn-default'
              to={getUrlTag('deegram', currentFeedType)} title='View posts with the deegram tag'>
              deegram
            </NavLink>
            {this.state.chooseTagList.map((tag, index) =>
              <NavLink activeClassName='btn-selected' id={`tag_${tag.name}`} className='btn btn-default'
                key={index} to={getUrlTag(tag.name, currentFeedType)}
                title={`View posts with the ${tag.name} tag`}>
                {tag.name}
              </NavLink>
            )}
            <Form className='navbar-item' onSubmit={this.goToCustomtag} style={{ display: 'grid', gridTemplateColumns: '4fr 1fr' }}>
              <FormControl name='tag-input' className='btn btn-default' placeholder='Enter tag...' style={{ width: '100%' }}
                title='View posts with a tag not listed ' />
              <Button type='submit'>Go</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeTagList}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    var url = document.location.href;
    var isProfileBlog = false;
    var urlParts = url.split('/');
    for (var i = 0; i < urlParts.length; i++) {
      if (urlParts[i] === (URL_PROFILE.slice(1))) {
        isProfileBlog = true;
        break;
      }
    }

    var selectImageMode = (
      <Button title='Feed has larger images,and has no preview of post body' onClick={() => this.setFeedMode('image')}
        className={this.state.feedOptions.feedMode === 'image' ? 'btn-selected' : ''}>
        Image mode
      </Button>
    )

    var selectBlogMode = (
      <Button title='Feed has smaller images, and shows a small preview of post body' onClick={() => this.setFeedMode('blog')}
        className={this.state.feedOptions.feedMode === 'blog' ? 'btn-selected' : ''}>
        Blog mode
      </Button>
    )

    var selectNew = (
      <NavLink to={`${this.state.feedOptions.tag ? `${getUrlTag(this.state.feedOptions.tag, 'new')}` : '/new'}`} title='Show new posts'
        activeClassName='btn-selected' className={`btn btn-default`}>
        New</NavLink>
    )

    var selectHot = (
      <NavLink to={`${this.state.feedOptions.tag ? `${getUrlTag(this.state.feedOptions.tag, 'hot')}` : '/hot'}`} title='Show hot posts'
        activeClassName='btn-selected' className={`btn btn-default`}>
        Hot</NavLink>
    )
    var selectTrending = (
      <NavLink to={`${this.state.feedOptions.tag ? `${getUrlTag(this.state.feedOptions.tag, 'trending')}` : '/trending'}`} title='Show trending posts'
        activeClassName='btn-selected' className={`btn btn-default`}>
        Trending</NavLink>
    )

    var feedOptionsTitle = (
      <span>{this.state.isMobile ? (this.state.feedOptions.tag ? this.state.feedOptions.tag : 'Feed') : 'Feed controls'}</span>
    )

    var feedController = (
      <Nav className={this.state.isMobile ? 'mobile-feed-controller' : ''}>
        <NavDropdown id='navbar-feed-controller' title={feedOptionsTitle} className={`navbar-text-button feed-controller-container${isProfileBlog ? '-profile' : ''}`}>
          <div className={`feed-top-container${isProfileBlog ? '-profile' : ''}`}>
            <Form className='navbar-item'>
              <Button onClick={this.openTagList} className='feed-title' title='Click to choose another tag'>
                {this.state.feedOptions.tag ?
                  (this.state.feedOptions.tag === '___' ? 'all tags' : this.state.feedOptions.tag)
                  : 'Select a tag'}
              </Button>
            </Form>
            {tagList}
            {isProfileBlog ? '' : (
              <div className='feed-sort-button-container'>
                {selectNew}{selectHot}{selectTrending}
              </div>
            )}
            <div className={isProfileBlog ? 'feed-type-button-container-profile' : 'feed-type-button-container'}>
              {selectImageMode}{selectBlogMode}
            </div>
          </div>
        </NavDropdown>
      </Nav>
    )

    if (this.state.cookiesEnabled !== 'true') {
      var enableCookies = (
        <div className='enable-cookies-container'>
          <div className='enable-cookies-banner'>
            <p>This website uses cookies to enhance your experience</p>
            <Button onClick={this.enableCookies}>
              Ok
          </Button>
          </div>
        </div>
      )
    }

    if (this.state.displayAppPopup && this.state.hasDeclinedApp !== 'true') {
      var appPopup = (
        <Modal show={this.state.displayAppPopup} onHide={this.hideGetAppPopup}>
          <Modal.Header closeButton>
            <Modal.Title>
              Deegram has a dedicated app!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='mobile-popup-body'>
            <p>It looks like you are accessing this website from a mobile phone. This website is not designed for
            mobile phones, please download the Deegram app for a better user experience.</p>
            <div className='mobile-popup-app-links'>
              <h4>Android:</h4>
              <h4>iOS: <br /> (on TestFlight)</h4>
              <a className='mobile-popup-googleplay' href={`https://play.app.goo.gl/?link=https://play.google.com/store/apps/details/?id=com.DecentralizeIT.DeeGram&ddl=1&pcampaignid=web_ddl_1`}>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
              </a>
              <a className='mobile-popup-appstore' href='https://testflight.apple.com/join/LHy49keZ'>
                <img alt='Try it on TestFlight' src={AppStoreIcon} />
              </a>
            </div>
            <input type='checkbox' id='mobile-popup-dont-ask-again' />
            <label htmlFor='mobile-popup-dont-ask-again'>Don't show again</label>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideGetAppPopup}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    if (this.state.showCookieWarning) {
      var cookieWarning = (
        <Modal show={this.state.showCookieWarning} onHide={this.hideCookieWarning}>
          <Modal.Header closeButton>
            <Modal.Title>
              This site uses cookies to enhance your experience by managing your login session.
              By logging in you consent to this site using cookies.
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={this.hideCookieWarning}>Decline</Button>
            <Button onClick={this.acceptCookies}>Accept</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    if (this.state.sessionExpired) {
      var sessionExpired = (
        <Modal show={this.state.sessionExpired} onHide={this.closeSessionExpired}>
          <Modal.Header closeButton>
            <Modal.Title>
              Login session expired
            </Modal.Title>
            <Modal.Body>
              Your login session has expired. Please login again to create posts and vote. You can still browse posts while not logged in.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeSessionExpired}>Stay logged out</Button>
              <Button onClick={this.goToLogin}>Log in</Button>
            </Modal.Footer>
          </Modal.Header>
        </Modal>
      )
    }
    return (
      <div>
        <div className="App">
          {appPopup}
          <Navbar fixedTop fluid className="main-navbar" id='mobile'>
            <Navbar.Header>
              <Navbar.Brand className={`navbar-home ${this.state.isMobile ? 'smaller-brand' : ''}`}>
                <Link to='/'><span>Deegram<img className='navbar-logo' src={Logo} /></span></Link>
              </Navbar.Brand>
              <Navbar.Toggle />
              {this.state.isMobile && (feedController)}
            </Navbar.Header>
            <Navbar.Collapse className="nav-collapse">
              {mobileDropdown}
              <Nav>
                <SearchForUser />
              </Nav>
              <Nav>{createPostButton}</Nav>
              <Nav>{feedController}</Nav>
              <Nav pullRight>
                {myFeedButton}
                {myProfileButton}
                {notificationsButton}
                <Nav className='register-login-buttons' eventKey={1}>
                  {registerButton}
                  {loginButton}
                </Nav>
                <NavDropdown
                  eventkey={4}
                  title={profileAvatar ? profileAvatar : ' '} //title is a required field
                  id="basic-nav-dropdown"
                  className="navbar-dropdown">
                  {profileItem}
                  {walletButton}
                  {notifications}
                  <li><NavLink to={URL_SETTINGS} activeClassName='selected'>Settings</NavLink></li>
                  <li><NavLink to={URL_APP_LINKS} activeClassName='selected'>App links</NavLink></li>
                  <li><a href={URL_FEEDBACK_FORM}>Give feedback</a></li>
                  {logoutButton}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {cookieWarning}
          {enableCookies}
          {sessionExpired}
        </div>

        <MainRouter feedOptions={this.state.feedOptions} callback={this.returnUrl} />
      </div>
    );
  }
}
