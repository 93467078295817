import {hivesignerClient} from './blockchainApi/hivesignerApi';
import Cookies from 'universal-cookie';
import { getDynamicGlobalProperties } from './blockchainApi/getDynamicGlobalProperties';
import { convertToFloat } from './helpers/convertToFloat';

//After login is complete, hivesigner sends user back to this domain with accessToken as a URL parameter
/**
 * Check if user is logged in based on cookies, localstorage and url params.
 * Resolves a promise if logged in, rejects if user session is expired. 
 */
export const isAuthenticated = () => {
  return new Promise((resolve, reject) => {
    const cookies = new Cookies();
    
    let url = new URL(document.location.href);
    let accessTokenUrl = url.searchParams.get('access_token');
    let expiresIn = url.searchParams.get('expires_in');
    //if there's an access token in the url, create a new cookie - implies user has just logged in
    if (accessTokenUrl) {
      let date = new Date();
      if (expiresIn && typeof expiresIn === "number") {
          expiresIn = parseInt(expiresIn);
          //expires in is in seconds, setTime() uses milliseconds
          date = new Date(date.setTime(date.getTime() + expiresIn * 1000));
      }
      else {
          date = new Date(date.setTime(date.getTime() + (7*24*60*60*1000)));
      }
      //secure: true for prod
      let isLocalHost = document.location.href.includes('localhost:3000')
      cookies.set('token', accessTokenUrl, {path: '/', expires: date, secure: isLocalHost ? false : true});
      hivesignerClient.setAccessToken(accessTokenUrl);
      hivesignerClient.me((err, res) => {
        //console.log(err, res);
        if (res && !res.error) {
          localStorage.setItem('userName', res.user);
          localStorage.setItem('loggedIn', true);

          let delegated = convertToFloat(res.account.received_vesting_shares);
          let votingPower = convertToFloat(res.account.vesting_shares);
          getDynamicGlobalProperties()
          .then(props => {
            //console.log(props);
            let hiveVests = convertToFloat(props.total_vesting_shares);
            let hivePower = convertToFloat(props.total_vesting_fund_hive);

            let powerRatio = (hiveVests / hivePower);
            votingPower = votingPower / powerRatio;
            delegated = delegated / powerRatio;

            let totalHivePower = votingPower + delegated;
            console.log(totalHivePower)
            if (totalHivePower > 500) { //should be 500 in prod
              localStorage.setItem('showSlider', true)
              localStorage.setItem('showSliderInFeed', true);
            }
            
            redirectToLastUrl();
          })
          .catch(err => console.log(err))
        }
      })
    }
    

    //gets cookie after it has potentially been set again
    let accessToken = cookies.get('token');
    if (accessToken && localStorage.getItem('userName')) {
      hivesignerClient.setAccessToken(accessToken);
      resolve({user: localStorage.getItem('userName')})
    }
    else {
      if (localStorage.getItem('loggedIn')) {
        //console.log('user session expired')
        reject('User session expired');
      }
      localStorage.setItem('loggedIn', '');
      localStorage.removeItem('loggedIn');
      localStorage.setItem('userName', '');
      localStorage.removeItem('userName');
      localStorage.removeItem('discoverList');
      localStorage.setItem('showSlider', '');
      localStorage.removeItem('showSlider');
      localStorage.removeItem('voteWeight');
    }
  })
}

const redirectToLastUrl = () => {
  var redirectUrl = sessionStorage.getItem('currentUrl');
  localStorage.removeItem('currentUrl');
  redirectUrl = redirectUrl ? redirectUrl : '/';
  document.location.href = redirectUrl;
}