import React from 'react';
import {Link} from 'react-router-dom';
import {Button, FormControl} from 'react-bootstrap';
import './settings.scss';
import { setVoteWeight, maxVoteWeight, minVoteWeight, getVoteWeight, URL_LICENSES, voteSliderStep, URL_EDIT_PROFILE } from '../../constants';
import HelpBoxes from '../../views/helpBoxes';

export class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          currentFeedMode: localStorage.getItem('feedMode'),
          voteWeight: getVoteWeight(),
          saved: false,
          loggedIn: localStorage.getItem('loggedIn'),

        }
    }

    saveSettings = () => {
      if (this.state.loggedIn) {
        this.setVoteWeight();
        this.toggleVoteSlider();
      }
      this.setState({saved: true})
    }

    handleVoteWeightChange = (e) => {
        e.preventDefault();
        var voteWeight = e.target.value.replace(/[^0-9]*/g, '');
        var parsed = parseInt(voteWeight);
        if (parsed || parsed === 0) {
            if (parsed > maxVoteWeight) {
                parsed = maxVoteWeight;
            }
            else if (parsed < minVoteWeight) {
                parsed = minVoteWeight
            }
            voteWeight = parsed;
        }
        this.setState({voteWeight: voteWeight, saved: false})
    }

    setVoteWeight = () => {
      localStorage.setItem('voteWeight', this.state.voteWeight)
      setVoteWeight(this.state.voteWeight);
    }

    toggleVoteSlider = () => {
      var el = document.getElementById('settings-show-slider-checkbox');
      if (el && el.checked) {
        localStorage.setItem('showSliderInFeed', true)
      }
      else {
        localStorage.removeItem('showSliderInFeed')
      }
    }

    setFeedMode = (mode = 'image') => {
      localStorage.setItem('feedMode', mode);
      this.setState({currentFeedMode: mode})
    }

    toggleShowVoteWeight = (e) => {
      try {
        localStorage.setItem('showVoteWeight', e.target.checked);
      }
      catch (ex) {
      }
    }

    componentDidMount() {
      document.title = 'Settings'
    }

    render() {
      var currentFeedMode = this.state.currentFeedMode;
      if (this.state.loggedIn) {
        var loggedInSettings = (        
          <div>
            {localStorage.getItem('showSlider') ? 
            <div className='settings-vote-weight'>
              <label htmlFor="vote-weight-slider">
                  Set vote weight <br /> From 0% to 100%
              </label>
              <div className='settings-vote-weight-input-bar'>
                  <Button className='settings-weight-min' onClick={() => this.setState({voteWeight: minVoteWeight})}>Min</Button>
                  <FormControl className='settings-vote-weight-input' type='text' value={this.state.voteWeight} onChange={this.handleVoteWeightChange}/>
                  <Button className='settings-weight-max' onClick={() => this.setState({voteWeight: maxVoteWeight})}>Max</Button>
              </div>
              <FormControl step={voteSliderStep} type='range' id='vote-weight-slider' min={minVoteWeight} max={maxVoteWeight} value={this.state.voteWeight} 
              onChange={this.handleVoteWeightChange}/>            
              <input id='settings-show-slider-checkbox' type='checkbox' defaultChecked={localStorage.getItem('showSliderInFeed')}/>
              <label htmlFor='settings-show-slider-checkbox'>
                Display vote slider
              </label>
            </div>
            : ''}
          </div>
        )
      }
        return (
          <div className='settings-page padding-top'>
            <div className='settings-feed-mode'>
              <h3>Feed mode</h3>
              <div className='settings-feed-mode-buttons'>
                <Button className={`settings-feedmode-button${currentFeedMode === 'image' ? ' btn-selected' : ''}`}
                  onClick={() => this.setFeedMode('image')} title='Larger images with no post body'>
                  Image mode
                </Button>
                <Button className={`settings-feedmode-button${currentFeedMode === 'blog' ? ' btn-selected' : ''}`}
                onClick={() => this.setFeedMode('blog')} title='Smaller images with a portion of post body'>
                  Blog mode
                </Button>
                <HelpBoxes 
                  title='What is feed mode?'
                  placement='bottom'
                  text='These buttons change how the feed looks. Image mode allows for larger images and does not display the body of a post. Blog mode does show a portion of the post body, but has smaller images'
                />   
              </div>     
            </div>
            <input id='settings-toggle-shown-vote-weight' type='checkbox' onChange={this.toggleShowVoteWeight}
            defaultChecked={localStorage.getItem('showVoteWeight')}/>
            <label htmlFor='settings-toggle-shown-vote-weight'>Show vote weight when viewing votes</label>              
            {loggedInSettings}  
          <br />
          <Button onClick={this.saveSettings}>Save</Button>
          {this.state.saved && <p>Saved!</p>}
          {this.state.loggedIn && (
            <>
            <br/>
            <Button disabled href={URL_EDIT_PROFILE} id="settings-edit-profile-button">Edit profile (temporarily disabled)</Button>
            </>
          )}
          <footer className='settings-footer'>
            <Link to={URL_LICENSES}>Licenses</Link>
          </footer>
        </div>
      )
    }
}