import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Popover} from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';

import { getNotifications } from '../../blockchainApi/getNotifications';
import {convertTime} from '../../helpers/convertTime';
import { getHiveAvatarImg, URL_POST } from '../../constants';

import './notification.css';

export class NotificationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          notificationsPerPage: 100,
          notifications: [],
        }
    }

    changeNotificationTypes = (e) => {
      //console.log(e.target.value)
      if (e && e.target) {
        this.setState({notificationType: e.target.value.toLowerCase()})
      }
    }
    
    //fetches notifications starting at the newest
  fetchNotifications = (from = this.state.lastNotification, amountFetched = 0) => {
    //exits recursion if there are no more notifications
    if (this.state.fetchedAll) return;

    var limit = this.state.notificationsPerPage;
    //sets the new fetch start from 1 before last notification fetched
    from = (from > 0) ? from - 1 : from;
    //if limit is greater than starting position, set limit to start position 
    limit = (limit > from && from !== -1) ? from : limit;
    getNotifications(from, limit)
    .then(res => {
      let notifications = res.notifications;
      let lastNotification = res.lastNotification;
      this.setState({
        notifications: this.state.notifications ? this.state.notifications.concat(notifications) : notifications,
        lastNotification: lastNotification,
      }, () => {
        //leaves recursion because all notifications have been fetched at this point
        if (lastNotification === 0) {
          this.setState({fetchedAll: true, loading: false})
          return;
        }
        amountFetched += notifications.length;
        //ensures a fetch gets at least 5 new visible notifications
        if (amountFetched < 5) {
          this.fetchNotifications(lastNotification, amountFetched);
        }
        else {
          this.setState({loading: false})
        }
      });
    })
    .catch(err => {
      //console.log(err)
    })
  }

  clickFetch = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }

  componentDidMount() {
    document.title = 'Notifications'
    this.fetchNotifications();
    document.addEventListener('scroll', this.trackScrolling);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const bottom = document.getElementById('notifications-bottom-page');
    const hasReachedBottom = bottom.getBoundingClientRect().top <= window.innerHeight;
    if (!this.state.loading && hasReachedBottom) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }

    render() {
      if (this.state.loading && !this.state.fetchedAll) {
        var scrollToLoad = (
          <PulseLoader />
        )
      }
      else if (!this.state.fetchedAll) {
        var scrollToLoad = (
          <div className='notifications-scroll-message'><Button onClick={this.clickFetch}>
            Scroll down to load more...
          </Button></div>
        )
      }
        return (
            <div className='notification-page padding-top'>
              <div className='notification-page-header'>
                <h1>Notifications center</h1>
                <form onChange={this.changeNotificationTypes}>
                  <label htmlFor='select-notification-type'>View notifications: </label>
                  <select as='select' id='select-notification-type'>
                    <option value=''>All </option>
                    <option value='vote'>Votes</option>
                    <option value='comment'>Comments</option>
                    <option value='transfer'>Transfers</option>
                    {/*<option value='delegate_vesting_shares'>Delegations</option>*/}
                  </select>
                </form>
              </div>
              <div className='notifications-list-container'>
                {this.state.notifications.map((notification, index) =>
                <Link to={notification.href} className='notification-link'>
                    <div key={index} style={{margin: '20px auto'}} 
                    className={`notification-page-single-container ${(!this.state.notificationType || notification.type === this.state.notificationType) ? 'notification-show' : 'notification-hide'}`}>
                      <img className='notification-page-user-avatar' src={getHiveAvatarImg(notification.user)} />
                      <p className='notification-page-message'>{notification.user} {notification.message}</p>
                      <p className='notification-page-timestamp'>{convertTime(notification.timestamp)}</p>
                    </div>    
                </Link>
                )}
                <div className='notifications-bottom-container'>
                  <div id='notifications-bottom-page'>{scrollToLoad}</div>
                </div>
              </div>
            </div>
        )
    }
}