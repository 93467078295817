import hive from '@hiveio/hive-js';

/**
 * Returns an account's follower and following count
 * @param {string} account username
 */
export const getFollowCount = (account) =>{
  return new Promise((resolve, reject) => {
    hive.api.getFollowCount(
      account,
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}
