import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {PostBlog} from './pages/newPost/postBlog';
import {ReadPostContainer} from './pages/fullPost/readPostContainer';
import {Profile} from './pages/profile/profile';
import {HelpRegister} from './pages/register/HelpRegister.js';
import {HomePage} from './pages/feed/homePage';
import { SearchResults } from './search/searchResults';
import { EditProfilePage } from './pages/profile/editProfilePage';
import { NotificationPage } from './pages/notifications/notificationPage';
import { WalletPage } from './pages/wallet/walletPage';
import { HivesignerCallback } from './hivesignerCallback';
import { LicensePage } from './pages/licensePage/licensePage';
import { DiscoverPage } from './discover/discover';
import { SettingsPage } from './pages/settings/settingsPage';
import { AppLinksPage } from './pages/applinks/appLinksPage';

import ScrollToTop from './helpers/scrollToTop';
import { URL_NEW_POST, URL_POST, URL_EDIT_PROFILE, URL_PROFILE, URL_SEARCH, URL_TAG, URL_WALLET, URL_SETTINGS, URL_LICENSES, URL_NOTIFICATIONS, URL_DISCOVER, getUrlProfile, getUrlTag, URL_APP_LINKS } from './constants';


import './navbar/mainNavBar.css';
import './styleTemplates.css';
import './contentPreviewStyle.css';
import './votes.css';

/**
 * Returns a component based on the document url.
 * Props should include feedOptions and a callback function with params feedtype and feedmode
 */
export class MainRouter extends React.Component {
  constructor(props) {
    super(props);
  }

  Home = ({match}) => {
    return <HomePage match = {match} feedOptions={this.props.feedOptions} callback={this.props.callback} />
  }

  post = () => (
    <PostBlog />
  )

  HivesignerCallback = () => (
    <HivesignerCallback />
  );
  
  Hot = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    return <HomePage match = {match} feedType="hot"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  Discover = () => (
    <DiscoverPage className='discover-container'/>
  )
  
  Trending = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    //console.log(match.params.tag)
    return <HomePage match = {match} feedType="trending"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  New = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    return <HomePage match = {match} feedType="new"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }

  AllTags = ({match}) => {
    match.params.tag = '___';
    //console.log(match)
    //console.log(this.props.feedOptions)
    return <HomePage match={match} feedType={match.params.type} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  FollowsFeed = ({match}) => (
    <HomePage  match={match} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  );
  
  Notifications = () => (
    <NotificationPage />
  )
  
  Register = () => (
    <div className = "registrer">
      <HelpRegister/>
    </div>
  )
  
  redirectToProfile = ({match}) => (
    document.location.href = getUrlProfile(match.params.userName)
  )

  appLinks = () => (
    <AppLinksPage/>
  )
  
  profilePage =({match}) => (
    <>
    <Profile match = {match} feedOptions={this.props.feedOptions} callback={this.props.callback}/></>
  )
  
  editProfilePage = ({match}) => (
    <EditProfilePage match = {match}/>
  )
  
  readPost = ({match}) =>(
    <ReadPostContainer match = {match}/>
  )
  
  Licenses = () => (
    <LicensePage />
  )
  
  Search = ({match}) => (
    <SearchResults match={match} />
  )
  
  settings = () => (
    <SettingsPage />
  )
  
  tag = ({match}) => (
    <HomePage match={match} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  )
  
  Wallet = ({match}) => (
    <WalletPage account={match.params.account} />
  )

  render() {
    return (
      <ScrollToTop>
      <Switch>
        <Route exact path='/complete/steemconnect/' component={this.HivesignerCallback}></Route>
        <Route exact path='/complete/hivesigner/' component={this.HivesignerCallback}></Route>
        <Route exact path={URL_DISCOVER} component={this.Discover}></Route>
        <Route exact path={URL_EDIT_PROFILE} component={this.editProfilePage}></Route>
        <Route exact path="/feed" component={this.FollowsFeed}></Route>
        <Route exact path={URL_LICENSES} component={this.Licenses}></Route>
        <Route exact path={URL_NOTIFICATIONS} component={this.Notifications}></Route>
        <Route exact path={URL_NEW_POST} component={this.post}></Route>
        <Route exact path={`${URL_PROFILE}/:userName`} component={this.profilePage}></Route>
        <Route exact path={`${URL_PROFILE}/:userName/`} component={this.profilePage}></Route>
        <Route path={`${URL_POST}/:author/:permlink`} component={ReadPostContainer}></Route>
        <Route path={`${URL_POST}/:author/:permlink/`} component={ReadPostContainer}></Route>
        <Route exact path='/register' component={this.Register}></Route>
    
        {/* tag routing */}
        <Route exact path={`${URL_TAG}/:tag`} component={this.tag}></Route>
        <Route exact path='/new' component={this.New}></Route>
        <Route exact path={`${URL_TAG}/:tag/new`} component={this.New}></Route>
        <Route exact path='/hot' component={this.Hot}></Route>
        <Route exact path={`${URL_TAG}/:tag/hot`} component={this.Hot}></Route>
        <Route exact path='/trending' component={this.Trending}></Route>
        <Route exact path={`${URL_TAG}/:tag/trending`} component={this.Trending}></Route>
        <Route exact path='/all-tags' component={this.AllTags}></Route>
        <Route exact path='/all-tags/:type' component={this.AllTags}></Route>
        
        <Route exact path={URL_APP_LINKS} component={this.appLinks}></Route>
        <Route exact path={URL_SETTINGS} component={this.settings}></Route>
        <Route exact path={`${URL_WALLET}/:account`} component={this.Wallet}></Route>
        <Route path='/@:userName/' component={this.redirectToProfile}></Route>
        <Route path={`${URL_SEARCH}`} component={this.Search}></Route>
        <Route path={`${URL_SEARCH}/:account`} component={this.Search}></Route>
        <Route exact path='/' component={this.Home}></Route>
        {/* Currently pops when it shouldnt, TODO fix it */}<Route component={NoMatch}></Route>
       </Switch>
      </ScrollToTop>
    )
  }
};

export const NoMatch = () => {
  document.title = '404 Page not found'
  var path = document.location.pathname;
  var words = path.split('/');
  var displayLinks = true;
  if (words.length > 5) displayLinks = false;
  if (displayLinks) {
    var displayTagLink = true;
    var displayProfileLink = true;
    for (var word of words) {
      if (word === 'tag') displayTagLink = false;
      if (word === 'profile') displayProfileLink = false;
    }
  }
  var lastWord = words.pop();

  return (
  <div className= 'padding-top error-404-page'>
    <h2>Error 404 - Page not found</h2>
    <h3>No match for path <code>{path}</code>.</h3>
    {displayLinks ? (<div><h3>Check that the url is correct or try one of the following:</h3>
    <ul>
      <li><a href={getUrlTag(lastWord)}>{getUrlTag(lastWord)}</a></li>
      <li><a href={getUrlProfile(lastWord)}>{getUrlProfile(lastWord)}</a></li>
    </ul>
    </div>) : ''}
  </div>
  )
}
