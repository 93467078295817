import hive from '@hiveio/hive-js';

/**
 * Returns a user's (user is "tag" in query) personal feed, which is based on the people they follow.
 * @param {{
 * tag: string,
 * limit: string | number,
 * start_author?: string,
 * start_permlink?: string
 * }} query Ex. format: {"tag":"bitcoin", "limit: 10"}
 */
export const getFeed = (query) => {
  return new Promise((resolve, reject) => {
    hive.api.getDiscussionsByFeed(
      query,
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}