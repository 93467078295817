import lodash from 'lodash'

/**
 * 
 * @param {string} jsonMetaData 
 */
export const getTagsFromMetadata = (jsonMetaData) => {
  const metadata = lodash.attempt(JSON.parse, jsonMetaData);
  if (lodash.isError(metadata)) return null;
  //console.log('getFromMetadata ', metadata.tags)
  return  metadata.tags
}

/**
 * 
 * @param {string} jsonMetaData 
 */
export const getImageFromMetadata = (jsonMetaData) => {
  const metadata = lodash.attempt(JSON.parse, jsonMetaData);
  if (lodash.isError(metadata)) return null;
  console.log('getFromMetadata ', metadata.image)
  return  metadata.image
}
